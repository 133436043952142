// src/components/ActivityPage.js

import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  CircularProgress,
  Alert,
  Box,
  List,
  ListItem,
  useMediaQuery,
  createTheme,
  ThemeProvider,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Button,
} from '@mui/material';
import { motion } from 'framer-motion';
import { auth, db } from '../firebase';
import {
  doc,
  getDoc,
  collectionGroup,
  query,
  where,
  orderBy,
  getDocs,
  collection,
} from 'firebase/firestore';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';

const theme = createTheme({
  palette: {
    primary: { main: '#1E88E5' },
    secondary: { main: '#D81B60' },
    error: { main: '#E53935' },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
});

const placementOptions = ['all', 'Stall/DT', 'Person', 'Counter'];

const timeframeOptions = [
  { value: '24h', label: 'Last 24 Hours' },
  { value: '7d', label: 'Last 7 Days' },
  { value: '30d', label: 'Last 30 Days' },
  { value: 'all', label: 'All Time' },
];

// Map L2R number to sentiment text and color
function getL2RSentimentAndColor(l2r) {
  let sentimentText = '';
  let borderColor = '';

  switch (l2r) {
    case 1:
      sentimentText = 'Very Dissatisfied';
      borderColor = 'red';
      break;
    case 2:
      sentimentText = 'Dissatisfied';
      borderColor = 'orange';
      break;
    case 3:
      sentimentText = 'Neither Satisfied nor Dissatisfied';
      borderColor = 'yellow';
      break;
    case 4:
      sentimentText = 'Satisfied';
      // Light Blue: can use a hex code or a named color
      borderColor = '#ADD8E6';
      break;
    case 5:
      sentimentText = 'Very Satisfied';
      borderColor = '#1E88E5'; // Blue (from primary)
      break;
    default:
      sentimentText = 'Unknown';
      borderColor = '#cccccc';
      break;
  }

  return { sentimentText, borderColor };
}

// Map responseDetail text to border color
function getResponseDetailColor(responseDetail) {
  // responseDetail can be something like "Very Satisfied", "Satisfied", etc.
  // We'll map these similarly to the l2r mapping:
  // "Very Dissatisfied" -> Red
  // "Dissatisfied" -> Orange
  // "Neither Satisfied nor Dissatisfied" -> Yellow
  // "Satisfied" -> Light Blue (#ADD8E6)
  // "Very Satisfied" -> Blue (#1E88E5)
  if (!responseDetail) return '#cccccc';

  const detailLower = responseDetail.toLowerCase();
  if (detailLower.includes('very dissatisfied')) return 'red';
  if (detailLower.includes('dissatisfied') && !detailLower.includes('very')) return 'orange';
  if (detailLower.includes('neither')) return 'yellow';
  if (detailLower.includes('satisfied') && !detailLower.includes('very')) return '#ADD8E6'; // Light blue
  if (detailLower.includes('very satisfied')) return '#1E88E5'; // Blue

  return '#cccccc';
}

const ActivityPage = () => {
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [responses, setResponses] = useState([]);

  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState('all');

  const [timeframe, setTimeframe] = useState('24h');
  const [selectedPlacement, setSelectedPlacement] = useState('all');

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) throw new Error('User not authenticated');

        const uid = currentUser.uid;
        const userDocRef = doc(db, 'users', uid);
        const userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) throw new Error('User data not found');

        const userData = userDocSnap.data();
        const clientIds = userData.clientIds || [];
        console.log('Clients:', clientIds);

        if (clientIds.length === 0) {
          console.log('No clientIds found for this user.');
          setClientOptions([]);
          setLoading(false);
          return;
        }

        const options = [];
        for (const cid of clientIds) {
          const clientDocRef = doc(db, 'clients', cid);
          const clientDocSnap = await getDoc(clientDocRef);
          if (clientDocSnap.exists()) {
            const cData = clientDocSnap.data();
            if (cData.displayName) {
              options.push({ clientId: cid, displayName: cData.displayName });
            } else {
              options.push({ clientId: cid, displayName: cid });
            }
          } else {
            options.push({ clientId: cid, displayName: cid });
          }
        }

        options.sort((a, b) =>
          a.displayName.localeCompare(b.displayName, undefined, { sensitivity: 'base' })
        );

        setClientOptions(options);
      } catch (error) {
        console.error('Error fetching clients:', error);
        setErrorMsg('An error occurred while fetching clients.');
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  const computeCutoff = () => {
    if (timeframe === 'all') return null;
    const now = Date.now();
    if (timeframe === '24h') {
      return new Date(now - 24 * 60 * 60 * 1000);
    } else if (timeframe === '7d') {
      return new Date(now - 7 * 24 * 60 * 60 * 1000);
    } else if (timeframe === '30d') {
      return new Date(now - 30 * 24 * 60 * 60 * 1000);
    }
    return null;
  };

  const fetchResponses = async () => {
    try {
      setLoading(true);
      setErrorMsg(null);
      setResponses([]);

      const cutoff = computeCutoff();
      console.log('Selected timeframe:', timeframe, 'Cutoff:', cutoff?.toISOString());
      console.log('Selected clientId:', selectedClientId);
      console.log('Selected placement:', selectedPlacement);

      if (!clientOptions || clientOptions.length === 0) {
        setResponses([]);
        setLoading(false);
        return;
      }

      const baseConditions = [];
      if (cutoff) {
        baseConditions.push(where('timestamp', '>', cutoff));
      }
      if (selectedPlacement !== 'all') {
        baseConditions.push(where('placement', '==', selectedPlacement));
      }

      let allResponses = [];

      const fetchClientResponses = async (clientIdsArray) => {
        const chunks = [];
        for (let i = 0; i < clientIdsArray.length; i += 10) {
          chunks.push(clientIdsArray.slice(i, i + 10));
        }

        let results = [];

        for (const chunk of chunks) {
          let q = collectionGroup(db, 'responses');
          const conditions = [where('clientId', 'in', chunk), ...baseConditions];
          q = query(q, ...conditions, orderBy('timestamp', 'desc'));

          const snap = await getDocs(q);
          snap.forEach((doc) => {
            const data = doc.data();
            results.push({ id: doc.id, ...data });
          });
        }
        return results;
      };

      const fetchOAFeedback = async (clientIdsArray) => {
        const feedbackRef = collection(db, 'feedback');
        const chunks = [];
        for (let i = 0; i < clientIdsArray.length; i += 10) {
          chunks.push(clientIdsArray.slice(i, i + 10));
        }

        let results = [];
        for (const chunk of chunks) {
          let q = feedbackRef;
          const conditions = [
            where('clientId', 'in', chunk),
            where('assignedTo', '==', 'OAfeedback')
          ];

          if (cutoff) {
            conditions.push(where('timestamp', '>', cutoff));
          }
          if (selectedPlacement !== 'all') {
            conditions.push(where('placement', '==', selectedPlacement));
          }

          q = query(q, ...conditions, orderBy('timestamp', 'desc'));
          const snap = await getDocs(q);
          snap.forEach((doc) => {
            const data = doc.data();
            results.push({ id: doc.id, ...data });
          });
        }
        return results;
      };

      if (selectedClientId === 'all') {
        const allClientIds = clientOptions.map((opt) => opt.clientId);
        const responsesData = await fetchClientResponses(allClientIds);
        const oaFeedbackData = await fetchOAFeedback(allClientIds);
        allResponses = [...responsesData, ...oaFeedbackData];
      } else {
        const responsesData = await (async () => {
          let q = collectionGroup(db, 'responses');
          const conditions = [where('clientId', '==', selectedClientId), ...baseConditions];
          q = query(q, ...conditions, orderBy('timestamp', 'desc'));

          const snap = await getDocs(q);
          const result = [];
          snap.forEach((doc) => {
            const data = doc.data();
            result.push({ id: doc.id, ...data });
          });
          return result;
        })();

        const oaFeedbackData = await (async () => {
          const feedbackRef = collection(db, 'feedback');
          const conditions = [
            where('clientId', '==', selectedClientId),
            where('assignedTo', '==', 'OAfeedback'),
          ];

          if (cutoff) {
            conditions.push(where('timestamp', '>', cutoff));
          }
          if (selectedPlacement !== 'all') {
            conditions.push(where('placement', '==', selectedPlacement));
          }

          let q = query(feedbackRef, ...conditions, orderBy('timestamp', 'desc'));
          const snap = await getDocs(q);
          const result = [];
          snap.forEach((doc) => {
            const data = doc.data();
            result.push({ id: doc.id, ...data });
          });
          return result;
        })();

        allResponses = [...responsesData, ...oaFeedbackData];
      }

      console.log(`Found ${allResponses.length} total documents (responses + OAfeedback).`);
      setResponses(allResponses);
    } catch (error) {
      console.error('Error fetching responses:', error);
      setErrorMsg('An error occurred while fetching activity details.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading && !errorMsg && clientOptions) {
      fetchResponses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeframe, selectedClientId, selectedPlacement, clientOptions]);

  if (loading && !errorMsg && responses.length === 0 && clientOptions.length === 0) {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            textAlign: 'center',
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'linear-gradient(135deg, #1E88E5 30%, #D81B60 100%)',
          }}
        >
          <CircularProgress style={{ color: '#fff' }} />
        </Box>
      </ThemeProvider>
    );
  }

  if (errorMsg) {
    return (
      <ThemeProvider theme={theme}>
        <Container
          sx={{
            textAlign: 'center',
            marginTop: '50px',
            padding: '0 20px',
          }}
        >
          <Alert severity="error">{errorMsg}</Alert>
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5 }}>
        <Container
          sx={{
            minHeight: '100vh',
            padding: '40px 20px',
          }}
        >
          <Typography
            variant={isSmallScreen ? 'h5' : 'h4'}
            gutterBottom
            sx={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}
          >
            Recent Activity Details
          </Typography>

          {/* Filters */}
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mb: 3 }}>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="client-select-label">Client</InputLabel>
              <Select
                labelId="client-select-label"
                value={selectedClientId}
                label="Client"
                onChange={(e) => setSelectedClientId(e.target.value)}
              >
                <MenuItem value="all">All Clients</MenuItem>
                {clientOptions.map((opt) => (
                  <MenuItem key={opt.clientId} value={opt.clientId}>
                    {opt.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="timeframe-select-label">Timeframe</InputLabel>
              <Select
                labelId="timeframe-select-label"
                value={timeframe}
                label="Timeframe"
                onChange={(e) => setTimeframe(e.target.value)}
              >
                {timeframeOptions.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="placement-select-label">Placement</InputLabel>
              <Select
                labelId="placement-select-label"
                value={selectedPlacement}
                label="Placement"
                onChange={(e) => setSelectedPlacement(e.target.value)}
              >
                {placementOptions.map((place) => (
                  <MenuItem key={place} value={place}>
                    {place === 'all' ? 'All Placements' : place}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="outlined"
              onClick={fetchResponses}
              sx={{ alignSelf: 'center', mt: { xs: 2, sm: 0 } }}
            >
              Refresh
            </Button>
          </Box>

          {loading ? (
            <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : responses.length === 0 ? (
            <Typography variant="body1">
              No recent activity for the selected criteria.
            </Typography>
          ) : (
            <List sx={{ mt: 2 }}>
              {responses.map((resp) => {
                const timestampDate = resp.timestamp.toDate();
                const formattedTime = timestampDate.toLocaleString();

                let sentimentText = '';
                let borderColor = '#cccccc';

                if (resp.assignedTo === 'OAfeedback') {
                  // OAfeedback entries use l2r field for sentiment
                  if (typeof resp.l2r === 'number') {
                    const { sentimentText: sText, borderColor: bColor } = getL2RSentimentAndColor(resp.l2r);
                    sentimentText = sText;
                    borderColor = bColor;
                  } else {
                    sentimentText = 'Unknown';
                  }
                } else {
                  // Non-OA items use responseDetail for sentiment
                  if (resp.responseDetail) {
                    sentimentText = resp.responseDetail;
                    borderColor = getResponseDetailColor(resp.responseDetail);
                  } else {
                    sentimentText = 'Unknown';
                  }
                }

                return (
                  <ListItem key={resp.id} sx={{ display: 'block', p: 0 }}>
                    <Box
                      sx={{
                        backgroundColor: '#ffffff',
                        padding: '20px',
                        borderRadius: '8px',
                        marginBottom: '16px',
                        boxShadow: '0px 2px 8px rgba(0,0,0,0.1)',
                        borderLeft: '4px solid',
                        borderColor: borderColor,
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                        {resp.displayName || 'Unknown Client'}
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box>
                          <Typography variant="body1" sx={{ fontWeight: '600', color: 'text.primary' }}>
                            Time Stamp:
                          </Typography>
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {formattedTime}
                          </Typography>
                        </Box>
                        {resp.assignedTo && (
                          <Box>
                            <Typography variant="body1" sx={{ fontWeight: '600', color: 'text.primary' }}>
                              Assigned To:
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                              {resp.assignedTo}
                            </Typography>
                          </Box>
                        )}
                        
                        <Box>
                          <Typography variant="body1" sx={{ fontWeight: '600', color: 'text.primary' }}>
                            Customer Sentiment:
                          </Typography>
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {sentimentText}
                          </Typography>
                        </Box>

                        {resp.feedback && (
                          <Box>
                            <Typography variant="body1" sx={{ fontWeight: '600', color: 'text.primary' }}>
                              Comment:
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                              {resp.feedback}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          )}
        </Container>
      </motion.div>
    </ThemeProvider>
  );
};

export default ActivityPage;
