import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
  Button,
  TextField,
  Tooltip,
  IconButton
} from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { db } from '../firebase';
import { collection, doc, getDoc, getDocs, query, where, updateDoc } from 'firebase/firestore';
import { AuthContext } from '../contexts/AuthContext';

const ManageTags = () => {
  const { currentUser } = useContext(AuthContext);
  const [userClientIds, setUserClientIds] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState('');

  const [placements, setPlacements] = useState(['Person', 'Stall/DT']);
  const [selectedPlacement, setSelectedPlacement] = useState('');

  const [assignStatusOptions] = useState(['Assigned', 'Unassigned']);
  const [selectedAssignStatus, setSelectedAssignStatus] = useState('Assigned');

  const [tags, setTags] = useState([]);

  const [loadingUserClients, setLoadingUserClients] = useState(true);
  const [loadingClients, setLoadingClients] = useState(false);
  const [loadingTags, setLoadingTags] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');
  // New warning state
  const [unassignWarning, setUnassignWarning] = useState('');

  const [editedAssignedTos, setEditedAssignedTos] = useState({});
  const [editingRows, setEditingRows] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      if (!currentUser) {
        setErrorMsg('User not authenticated.');
        return;
      }

      setLoadingUserClients(true);
      setErrorMsg('');
      setUnassignWarning('');
      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userDocRef);
        if (!userSnap.exists()) {
          setErrorMsg('User data not found.');
          return;
        }

        const userData = userSnap.data();
        const cIds = userData.clientIds || [];
        setUserClientIds(cIds);
      } catch (error) {
        console.error('Error fetching user clients:', error);
        setErrorMsg('Failed to load user clients. Please try again.');
      } finally {
        setLoadingUserClients(false);
      }
    };

    fetchUserData();
  }, [currentUser]);

  useEffect(() => {
    const fetchClients = async () => {
      if (userClientIds.length === 0) {
        setClients([]);
        return;
      }

      if (userClientIds.length > 10) {
        setErrorMsg('Too many clientIds. Need multiple queries or another approach.');
        return;
      }

      setLoadingClients(true);
      setErrorMsg('');
      setUnassignWarning('');

      try {
        const clientsRef = collection(db, 'clients');
        const q = query(clientsRef, where('__name__', 'in', userClientIds));
        const snapshot = await getDocs(q);

        const clientsData = [];
        snapshot.forEach(docSnap => {
          const data = docSnap.data();
          clientsData.push({ id: docSnap.id, ...data });
        });
        setClients(clientsData);
      } catch (error) {
        console.error('Error fetching assigned clients:', error);
        setErrorMsg('Failed to load assigned clients. Please try again.');
      } finally {
        setLoadingClients(false);
      }
    };

    if (!loadingUserClients && userClientIds.length > 0) {
      fetchClients();
    }
  }, [loadingUserClients, userClientIds]);

  const fetchTags = async () => {
    if (!selectedClientId || !selectedPlacement) {
      setTags([]);
      return;
    }

    setLoadingTags(true);
    setErrorMsg('');
    setUnassignWarning('');

    try {
      const tagsRef = collection(db, 'clients', selectedClientId, 'nfcTags');
      const tagsQ = query(tagsRef, where('placement', '==', selectedPlacement));
      const tagsSnap = await getDocs(tagsQ);

      const tagsData = [];
      tagsSnap.forEach(docSnap => {
        const data = docSnap.data();
        tagsData.push(data);
      });

      let filteredTags = tagsData;

      if (selectedAssignStatus === 'Assigned') {
        filteredTags = tagsData.filter(tag => tag.assignedTo !== 'Unassigned');
      } else if (selectedAssignStatus === 'Unassigned') {
        filteredTags = tagsData.filter(tag => tag.assignedTo === 'Unassigned');
      }

      setTags(filteredTags);

      const initialEdits = {};
      const initialEditing = {};
      filteredTags.forEach(tag => {
        const tagKey = tag.tagId || tag.id;
        initialEdits[tagKey] = tag.assignedTo || '';
        initialEditing[tagKey] = false;
      });
      setEditedAssignedTos(initialEdits);
      setEditingRows(initialEditing);

    } catch (error) {
      console.error('Error fetching tags:', error);
      setErrorMsg('Failed to load tags. Please try again.');
    } finally {
      setLoadingTags(false);
    }
  };

  useEffect(() => {
    fetchTags();
  }, [selectedClientId, selectedPlacement, selectedAssignStatus]);

  const handleRequestId = async (tag) => {
    if (!selectedClientId || !tag.tagId) return;

    try {
      const tagDocRef = doc(db, 'clients', selectedClientId, 'nfcTags', tag.tagId);
      await updateDoc(tagDocRef, { id: tag.tagId });
      await fetchTags();
    } catch (error) {
      console.error('Error requesting ID:', error);
      setErrorMsg('Failed to request ID. Please try again.');
    }
  };

  const handleSaveAssignedTo = async (tag) => {
    const tagKey = tag.tagId || tag.id;
    const newAssignedTo = editedAssignedTos[tagKey];

    if (!selectedClientId || !tagKey || !newAssignedTo) return;

    if (newAssignedTo.trim().toLowerCase() === 'unassigned') {
      // Show warning and revert
      setUnassignWarning("You cannot manually set 'Assigned To' to Unassigned. Please use the Unassign action button.");
      const originalTag = tags.find(t => (t.tagId || t.id) === tagKey);
      const originalAssignedTo = originalTag ? originalTag.assignedTo || '' : '';
      setEditedAssignedTos(prev => ({ ...prev, [tagKey]: originalAssignedTo }));
      setEditingRows(prev => ({ ...prev, [tagKey]: false }));
      return;
    }

    try {
      const tagDocRef = doc(db, 'clients', selectedClientId, 'nfcTags', tag.tagId || tag.id);
      await updateDoc(tagDocRef, { assignedTo: newAssignedTo });
      await fetchTags();
    } catch (error) {
      console.error('Error saving assignedTo:', error);
      setErrorMsg('Failed to save assignedTo. Please try again.');
    }
  };

  const handleEditClick = (tag) => {
    const tagKey = tag.tagId || tag.id;
    setEditingRows(prev => ({ ...prev, [tagKey]: true }));
  };

  const handleCancelEdit = (tag) => {
    const tagKey = tag.tagId || tag.id;
    const originalTag = tags.find(t => (t.tagId || t.id) === tagKey);
    const originalAssignedTo = originalTag ? originalTag.assignedTo || '' : '';
    setEditedAssignedTos(prev => ({ ...prev, [tagKey]: originalAssignedTo }));
    setEditingRows(prev => ({ ...prev, [tagKey]: false }));
  };

  const handleResetStats = async (tag) => {
    if (!selectedClientId || (!tag.id && !tag.tagId)) return;
    const confirmed = window.confirm('Are you sure you want to reset the stats for this tag?');
    if (!confirmed) return;

    try {
      const tagKey = tag.tagId || tag.id;
      const tagDocRef = doc(db, 'clients', selectedClientId, 'nfcTags', tagKey);
      await updateDoc(tagDocRef, { totalHits: 0, totalYes: 0, totalNo: 0 });
      await fetchTags();
    } catch (error) {
      console.error('Error resetting stats:', error);
      setErrorMsg('Failed to reset stats. Please try again.');
    }
  };

  const handleUnassign = async (tag) => {
    if (!selectedClientId || (!tag.id && !tag.tagId)) return;
    const confirmed = window.confirm('Are you sure you want to unassign this tag and reset stats?');
    if (!confirmed) return;

    try {
      const tagKey = tag.tagId || tag.id;
      const tagDocRef = doc(db, 'clients', selectedClientId, 'nfcTags', tagKey);
      await updateDoc(tagDocRef, {
        assignedTo: 'Unassigned',
        totalHits: 0,
        totalYes: 0,
        totalNo: 0
      });
      await fetchTags();
    } catch (error) {
      console.error('Error unassigning tag:', error);
      setErrorMsg('Failed to unassign tag. Please try again.');
    }
  };

  const formatLastHit = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = timestamp.toDate();
    const datePart = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    const timePart = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit'
    });

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="body2">{datePart}</Typography>
        <Typography variant="body2">{timePart}</Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography
        variant="h4"
        sx={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif', mb: 2 }}
      >
        Manage Tags
      </Typography>

      {errorMsg && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMsg}
        </Alert>
      )}
      {unassignWarning && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          {unassignWarning}
        </Alert>
      )}

      {/* Client Selection */}
      <Box sx={{ mb: 2 }}>
        {loadingUserClients ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
            <CircularProgress size={24} />
          </Box>
        ) : (
          <FormControl fullWidth disabled={loadingClients || userClientIds.length === 0}>
            <InputLabel id="client-select-label">Select Client</InputLabel>
            {loadingClients ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <Select
                labelId="client-select-label"
                value={selectedClientId}
                label="Select Client"
                onChange={(e) => setSelectedClientId(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {clients.map(client => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.displayName || client.id}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        )}
      </Box>

      {/* Placement and Assigned/Unassigned Selection */}
      <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
        <FormControl fullWidth disabled={!selectedClientId}>
          <InputLabel id="placement-select-label">Select Placement</InputLabel>
          <Select
            labelId="placement-select-label"
            value={selectedPlacement}
            label="Select Placement"
            onChange={(e) => setSelectedPlacement(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {placements.map((placement) => (
              <MenuItem key={placement} value={placement}>
                {placement}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth disabled={!selectedPlacement}>
          <InputLabel id="assign-status-select-label">Assigned/Unassigned</InputLabel>
          <Select
            labelId="assign-status-select-label"
            value={selectedAssignStatus}
            label="Assigned/Unassigned"
            onChange={(e) => setSelectedAssignStatus(e.target.value)}
          >
            <MenuItem value="Assigned">Assigned</MenuItem>
            <MenuItem value="Unassigned">Unassigned</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Tags Table */}
      {loadingTags ? (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedClientId && selectedPlacement && (
        tags.length === 0 ? (
          <Typography variant="body1" sx={{ color: 'text.secondary', mt: 2 }}>
            No tags found for the selected client and placement.
          </Typography>
        ) : (
          <Paper sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>ID</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>Assigned To</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>Total Hits</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>Total Yes</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>Total No</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>Last Hit</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tags.map((tag, index) => {
                  const tagKey = tag.tagId || tag.id || index;
                  const currentAssignedTo = editedAssignedTos[tagKey] || '';
                  const totalHits = tag.totalHits || 0;
                  const totalYes = tag.totalYes || 0;
                  const totalNo = tag.totalNo || 0;
                  const isEditing = editingRows[tagKey];

                  const lastHitElement = formatLastHit(tag.lastHit);

                  return (
                    <TableRow key={tag.id || tag.tagId || index}>
                      <TableCell align="center" sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                        {tag.id ? (
                          tag.id
                        ) : (
                          <Button variant="outlined" size="small" onClick={() => handleRequestId(tag)}>
                            Request ID
                          </Button>
                        )}
                      </TableCell>
                      <TableCell align="center" sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                        {isEditing ? (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center' }}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={currentAssignedTo}
                              onChange={(e) =>
                                setEditedAssignedTos(prev => ({ ...prev, [tagKey]: e.target.value }))
                              }
                              sx={{ width: '150px' }}
                            />
                            <Button variant="contained" size="small" onClick={() => handleSaveAssignedTo(tag)}>
                              Save
                            </Button>
                            <Button variant="text" size="small" onClick={() => handleCancelEdit(tag)}>
                              Cancel
                            </Button>
                          </Box>
                        ) : (
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: 'Montserrat, sans-serif',
                              cursor: 'pointer',
                              '&:hover': { textDecoration: 'underline', color: 'primary.main' }
                            }}
                            onClick={() => handleEditClick(tag)}
                          >
                            {currentAssignedTo}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="center" sx={{ fontFamily: 'Montserrat, sans-serif' }}>{totalHits}</TableCell>
                      <TableCell align="center" sx={{ fontFamily: 'Montserrat, sans-serif' }}>{totalYes}</TableCell>
                      <TableCell align="center" sx={{ fontFamily: 'Montserrat, sans-serif' }}>{totalNo}</TableCell>
                      <TableCell align="center" sx={{ fontFamily: 'Montserrat, sans-serif' }}>{lastHitElement}</TableCell>
                      <TableCell align="center" sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                        <Tooltip title="Reset Stats">
                          <IconButton size="small" onClick={() => handleResetStats(tag)}>
                            <RestartAltIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Unassign">
                          <IconButton size="small" onClick={() => handleUnassign(tag)}>
                            <PersonOffIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        )
      )}
    </Box>
  );
};

export default ManageTags;
