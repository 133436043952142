import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Box,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Typography,
  Grid,
  IconButton
} from '@mui/material';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import GetAppIcon from '@mui/icons-material/GetApp';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import qrcode from 'qrcode';
import CryptoJS from 'crypto-js';

// Helper function to hash a password using SHA-256 with crypto-js
function hashPassword(password) {
  return CryptoJS.SHA256(password).toString();
}

const CONTEST_TYPES = [
  { value: 'employeeSurveyCapture', label: 'Employee Survey Capture' },
];

const CONTEST_FOCUS_OPTIONS = [
  { value: 'totalSurveys', label: 'Total Surveys' },
  { value: 'bestSentiment', label: 'Best Sentiment' },
];

const TOTAL_SURVEYS_OPTIONS = [
  { value: 'overall', label: 'Overall' },
  { value: 'perHour', label: 'Per carhop hour worked' },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const CreateContestModal = ({
  open,
  onClose,
  onSubmit,
  availableClients = [], // Default to empty array
  selectedContest
}) => {
  const [selectedClientIds, setSelectedClientIds] = useState([]);

  const [contestType, setContestType] = useState('');
  const [contestFocus, setContestFocus] = useState('');
  const [totalSurveysOption, setTotalSurveysOption] = useState('');
  const [employees, setEmployees] = useState([]);
  const [selectedIgnore, setSelectedIgnore] = useState([]);

  // Basic Contest Info
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Leaderboard password (plain text)
  const [leaderboardPassword, setLeaderboardPassword] = useState('');

  useEffect(() => {
    if (open) {
      if (selectedContest) {
        setContestType(selectedContest.contestType || '');
        setContestFocus(selectedContest.contestFocus || '');
        setTotalSurveysOption(selectedContest.totalSurveysOption || '');
        setSelectedIgnore(selectedContest.ignoreList || []);
        setTitle(selectedContest.title || '');
        setDescription(selectedContest.description || '');
        if (selectedContest.startDate) {
          setStartDate(selectedContest.startDate.toDate().toISOString().split('T')[0]);
        } else {
          setStartDate('');
        }
        if (selectedContest.endDate) {
          setEndDate(selectedContest.endDate.toDate().toISOString().split('T')[0]);
        } else {
          setEndDate('');
        }
        setLeaderboardPassword(selectedContest.leaderboardPassword || '');

        let existingClientIds = [];
        if (Array.isArray(selectedContest.clientIds)) {
          existingClientIds = selectedContest.clientIds;
        } else if (selectedContest.clientId) {
          existingClientIds = [selectedContest.clientId];
        }
        setSelectedClientIds(existingClientIds);
      } else {
        // Creating a new contest
        setContestType('');
        setContestFocus('');
        setTotalSurveysOption('');
        setSelectedIgnore([]);
        setTitle('');
        setDescription('');
        setStartDate('');
        setEndDate('');
        setLeaderboardPassword('');
        setSelectedClientIds([]);
      }
    }
  }, [selectedContest, open]);

  // Fetch employees from all selected clients
  useEffect(() => {
    const fetchEmployees = async () => {
      if (!open || selectedClientIds.length === 0) {
        setEmployees([]);
        return;
      }

      try {
        const allEmps = new Set();
        for (const cid of selectedClientIds) {
          const tagsRef = collection(db, 'clients', cid, 'nfcTags');
          const qEmp = query(tagsRef, where('placement', '==', 'Person'));
          const snap = await getDocs(qEmp);
          snap.forEach(doc => {
            const data = doc.data();
            if (data.assignedTo) {
              allEmps.add(data.assignedTo);
            }
          });
        }
        setEmployees(Array.from(allEmps));
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    fetchEmployees();
  }, [selectedClientIds, open]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    if (!startDate || !endDate) {
      console.error("Start Date or End Date is missing");
      return;
    }

    const [sYear, sMonth, sDay] = startDate.split('-');
    const [eYear, eMonth, eDay] = endDate.split('-');

    if (!sYear || !sMonth || !sDay || !eYear || !eMonth || !eDay) {
      console.error("Invalid Start or End Date format");
      return;
    }

    const start = new Date(parseInt(sYear), parseInt(sMonth) - 1, parseInt(sDay), 12, 0, 0);
    const end = new Date(parseInt(eYear), parseInt(eMonth) - 1, parseInt(eDay), 12, 0, 0);

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      console.error("Invalid Start or End Date");
      return;
    }

    const basicInfo = {
      title,
      description,
      startDate: start,
      endDate: end
    };

    const contestSettings = {
      basicInfo,
      contestType,
      contestFocus,
      totalSurveysOption: totalSurveysOption || null,
      ignoreList: selectedIgnore,
      clientIds: selectedClientIds
    };

    if (leaderboardPassword) {
      const hashed = hashPassword(leaderboardPassword);
      contestSettings.hashedPassword = hashed;
    }

    console.log(contestSettings);
    onSubmit(contestSettings);
  };

  const showFocusOptions = contestType === 'employeeSurveyCapture';
  const showTotalSurveysOptions = showFocusOptions && contestFocus === 'totalSurveys';
  const isPerHour = totalSurveysOption === 'perHour';

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const isEditing = !!selectedContest;

  let baseUrl = 'https://truemetric.io';
  if (window.location.hostname === '208.109.244.166') {
    baseUrl = `http://${window.location.hostname}:3005`;
  }

  const hashedPassword = selectedContest?.hashedPassword;
  const leaderboardUrl = (isEditing && hashedPassword && selectedContest?.id)
    ? `${baseUrl}/leaderboard?contestId=${selectedContest.id}&pw=${encodeURIComponent(hashedPassword)}`
    : '';

  const handleCopyUrl = () => {
    if (leaderboardUrl) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(leaderboardUrl).catch(err => {
          console.error('Failed to copy URL:', err);
        });
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = leaderboardUrl;
        textArea.style.position = 'fixed';
        textArea.style.left = '-9999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('Fallback: Unable to copy', err);
        }
        document.body.removeChild(textArea);
      }
    }
  };

  const handleOpenLeaderboard = () => {
    if (leaderboardUrl) {
      window.open(leaderboardUrl, '_blank');
    }
  };

  const handleDownloadQrPdf = async () => {
    if (!leaderboardUrl) return;
    try {
      const qrDataUrl = await qrcode.toDataURL(leaderboardUrl, { width: 200, margin: 1 });
      const pdf = new jsPDF({
        unit: 'pt',
        format: 'letter',
      });

      const pageWidth = pdf.internal.pageSize.getWidth();

      pdf.setFontSize(24);
      pdf.setFont('helvetica', 'bold');
      pdf.text('Leaderboard Access', pageWidth / 2, 100, { align: 'center' });

      pdf.setFontSize(14);
      pdf.setFont('helvetica', 'normal');
      pdf.text('Scan the QR code below to view the leaderboard.', pageWidth / 2, 130, { align: 'center' });

      pdf.addImage(qrDataUrl, 'PNG', (pageWidth - 150) / 2, 160, 150, 150);
      pdf.save('leaderboard_qr_code.pdf');
    } catch (error) {
      console.error('Error generating QR code PDF:', error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{isEditing ? 'Edit Contest Settings' : 'Create Contest'}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Basic Contest Information
        </Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Contest Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Contest Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Start Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="End Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Contest Settings
        </Typography>

        {/* Multi-select for clients (stores) */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="client-select-label">Select Clients (Stores)</InputLabel>
          <Select
            labelId="client-select-label"
            multiple
            value={selectedClientIds}
            onChange={(e) => setSelectedClientIds(e.target.value)}
            input={<OutlinedInput label="Select Clients (Stores)" />}
            renderValue={(selected) => {
              const names = availableClients
                .filter(c => selected.includes(c.id))
                .map(c => c.name);
              return names.join(', ');
            }}
            MenuProps={menuProps}
          >
            {availableClients.map((client) => (
              <MenuItem key={client.id} value={client.id}>
                <Checkbox checked={selectedClientIds.indexOf(client.id) > -1} />
                <ListItemText primary={client.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="contest-type-label">Contest Type</InputLabel>
          <Select
            labelId="contest-type-label"
            value={contestType}
            label="Contest Type"
            onChange={(e) => setContestType(e.target.value)}
          >
            {CONTEST_TYPES.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {showFocusOptions && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="contest-focus-label">Contest Focus</InputLabel>
            <Select
              labelId="contest-focus-label"
              value={contestFocus}
              label="Contest Focus"
              onChange={(e) => setContestFocus(e.target.value)}
            >
              {CONTEST_FOCUS_OPTIONS.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {showTotalSurveysOptions && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="total-surveys-option-label">Total Surveys Option</InputLabel>
            <Select
              labelId="total-surveys-option-label"
              value={totalSurveysOption}
              label="Total Surveys Option"
              onChange={(e) => setTotalSurveysOption(e.target.value)}
            >
              {TOTAL_SURVEYS_OPTIONS.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {isPerHour && (
          <Box sx={{ mb: 2 }}>
            <Tooltip title="Selecting per carhop hour worked means you'll need to manually enter hours worked by each employee for fair comparison.">
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Note: 'Per carhop hour worked' requires manual hour entry.
              </Typography>
            </Tooltip>
          </Box>
        )}

        {employees.length > 0 && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="ignore-list-label">Ignore List (Select Employees)</InputLabel>
            <Select
              labelId="ignore-list-label"
              multiple
              value={selectedIgnore}
              onChange={(e) => setSelectedIgnore(e.target.value)}
              input={<OutlinedInput label="Ignore List" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={menuProps}
            >
              {employees.map((emp) => (
                <MenuItem key={emp} value={emp}>
                  <Checkbox checked={selectedIgnore.indexOf(emp) > -1} />
                  <ListItemText primary={emp} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <Typography variant="h6" sx={{ mb: 2, mt: 3 }}>
          Leaderboard Access
        </Typography>
        <Typography variant="body2" sx={{ mb: 1, color: 'text.secondary' }}>
          Set a password to protect the leaderboard. This password will be hashed and the URL will show only the hash, making it hard to discern the original password.
        </Typography>
        <TextField
          label="Leaderboard Password"
          type="text"
          value={leaderboardPassword}
          onChange={(e) => setLeaderboardPassword(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />

        {isEditing && hashedPassword && leaderboardUrl && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" sx={{ mb: 1, color: 'text.secondary' }}>
              Leaderboard Access Link (hash only, password hidden):
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 1 }}>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {leaderboardUrl}
              </Typography>
              <IconButton size="small" onClick={handleCopyUrl}>
                <ContentCopyIcon fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={handleOpenLeaderboard}>
                <OpenInNewIcon fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={handleDownloadQrPdf}>
                <GetAppIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        )}

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={
            selectedClientIds.length === 0 || // must select at least one client
            !contestType ||
            (showFocusOptions && !contestFocus) ||
            (showTotalSurveysOptions && !totalSurveysOption) ||
            !title || !description || !startDate || !endDate
          }
        >
          {isEditing ? 'Save Changes' : 'Save Contest'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateContestModal;
