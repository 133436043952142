import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  Alert,
  useTheme,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import { auth, db } from '../firebase';
import {
  doc,
  getDoc,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  orderBy,
  updateDoc,
  or
} from 'firebase/firestore';
import { format } from 'date-fns';
import CreateContestModal from './CreateContestModal'; 
import ContestDetailsModal from './ContestDetailsModal';

const ContestsPage = () => {
  const theme = useTheme();
  
  const [loading, setLoading] = useState(true);
  const [clientIds, setClientIds] = useState([]);
  const [contests, setContests] = useState([]);

  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedContest, setSelectedContest] = useState(null); 
  const [contestSettings, setContestSettings] = useState(null); 

  const [detailsOpen, setDetailsOpen] = useState(false);
  const [detailsContest, setDetailsContest] = useState(null);

  // New state for switching between active and archived contests
  const [viewMode, setViewMode] = useState('active');

  useEffect(() => {
    const fetchUserClients = async () => {
      setLoading(true);
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) throw new Error('User not authenticated');

        const uid = currentUser.uid;
        const userDocRef = doc(db, 'users', uid);
        const userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) throw new Error('User data not found');

        const userData = userDocSnap.data();
        const cIds = userData.clientIds || [];
        setClientIds(cIds);
      } catch (error) {
        console.error('Error fetching user clients:', error);
        setErrorMsg('Failed to load clients. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    fetchUserClients();
  }, []);

  useEffect(() => {
    const fetchContests = async () => {
      if (clientIds.length === 0) {
        setContests([]);
        return;
      }
      setLoading(true);

      try {
        // Fetch all contests that match any of the user's clientIds
        // Since Firestore doesn't support 'where in' for arrays directly with collection queries,
        // we may have to run multiple queries (one per client) or restructure data.
        // For simplicity, let's run multiple queries and combine:
        
        const contestsRef = collection(db, 'contests');
        const allContests = [];

        for (const cid of clientIds) {
          // Fetch contests that have either a single clientId matching cid or have clientIds array containing cid
          // We'll just query by clientIds arrays or single clientId fields:
          // If your data model always stores clientIds as arrays, you can do:
          // query where array-contains cid
          // If you have a mix (old contests with clientId and new ones with clientIds), do two queries and combine.
          
          // Query for new style (multi-client) contests
          const multiQ = query(contestsRef, where('clientIds', 'array-contains', cid), orderBy('startDate', 'desc'));
          const multiSnap = await getDocs(multiQ);
          multiSnap.forEach(docSnap => {
            const data = docSnap.data();
            allContests.push({ id: docSnap.id, ...data });
          });

          // Query for old style (single-client) contests (if needed)
          const singleQ = query(contestsRef, where('clientId', '==', cid), orderBy('startDate', 'desc'));
          const singleSnap = await getDocs(singleQ);
          singleSnap.forEach(docSnap => {
            const data = docSnap.data();
            allContests.push({ id: docSnap.id, ...data });
          });
        }

        // Remove duplicates (if a contest belongs to multiple clients in theory)
        const uniqueContests = Array.from(new Map(allContests.map(c => [c.id, c])).values());

        setContests(uniqueContests);
      } catch (error) {
        console.error('Error fetching contests:', error);
        setErrorMsg('Failed to load contests. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchContests();
  }, [clientIds]);

  const handleOpenModal = () => {
    if (clientIds.length === 0) {
      setErrorMsg('No clients available. Cannot create a contest.');
      return;
    }
    setErrorMsg('');
    setSuccessMsg('');
    setSelectedContest(null);
    setModalOpen(true);
  };

  const handleEditClick = (contest, e) => {
    e.stopPropagation();
    setSelectedContest(contest);
    setErrorMsg('');
    setSuccessMsg('');
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedContest(null);
  };

  const handleModalSubmit = async ({ basicInfo, ...settings }) => {
    setContestSettings(settings);
    setLoading(true);
    setErrorMsg('');
    setSuccessMsg('');

    try {
      const { title, description, startDate, endDate } = basicInfo;

      const contestsRef = collection(db, 'contests');
      const newContestData = {
        title,
        description,
        startDate, 
        endDate,
        createdAt: new Date(),
        ...settings,
      };

      // If we have old code that used `clientId`, we no longer set it. 
      // Everything now should be in `clientIds` array.

      if (selectedContest) {
        // Update existing contest
        const contestDocRef = doc(db, 'contests', selectedContest.id);
        delete newContestData.createdAt; 
        await updateDoc(contestDocRef, newContestData);
        setSuccessMsg('Contest updated successfully!');
      } else {
        // Create new contest
        await addDoc(contestsRef, newContestData);
        setSuccessMsg('Contest created successfully!');
      }

      // Refresh contests
      const refreshAllContests = [];
      for (const cid of clientIds) {
        const multiQ = query(contestsRef, where('clientIds', 'array-contains', cid), orderBy('startDate', 'desc'));
        const multiSnap = await getDocs(multiQ);
        multiSnap.forEach(docSnap => {
          const data = docSnap.data();
          refreshAllContests.push({ id: docSnap.id, ...data });
        });

        const singleQ = query(contestsRef, where('clientId', '==', cid), orderBy('startDate', 'desc'));
        const singleSnap = await getDocs(singleQ);
        singleSnap.forEach(docSnap => {
          const data = docSnap.data();
          refreshAllContests.push({ id: docSnap.id, ...data });
        });
      }

      const uniqueContests = Array.from(new Map(refreshAllContests.map(c => [c.id, c])).values());
      setContests(uniqueContests);

    } catch (error) {
      console.error('Error saving contest:', error);
      setErrorMsg('Failed to save contest. Please try again.');
    } finally {
      setLoading(false);
      setModalOpen(false);
      setSelectedContest(null);
      setContestSettings(null);
    }
  };

  const handleCardClick = (contest) => {
    setDetailsContest(contest);
    setDetailsOpen(true);
  };

  const handleDetailsClose = () => {
    setDetailsOpen(false);
    setDetailsContest(null);
  };

  // Determine active vs archived
  const now = new Date();
  const filteredContests = contests.filter((contest) => {
    const endDate = contest.endDate.toDate();
    if (viewMode === 'active') {
      return endDate >= now; // still active
    } else {
      // archived
      return endDate < now;
    }
  });

  if (loading && contests.length === 0 && clientIds.length === 0 && !errorMsg) {
    return (
      <Box
        sx={{
          textAlign: 'center',
          marginTop: '50px',
          display: 'flex',
          justifyContent: 'center',
          background: `linear-gradient(135deg, ${theme.palette.grey[100]} 0%, ${theme.palette.grey[300]} 100%)`,
          minHeight: '100vh',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: '40px 20px',
        background: `linear-gradient(135deg, ${theme.palette.grey[50]} 0%, ${theme.palette.grey[200]} 100%)`,
        minHeight: '100vh'
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          fontFamily: 'Montserrat, sans-serif',
          textAlign: 'center',
          mb: 4
        }}
      >
        Contests
      </Typography>

      {errorMsg && (
        <Alert severity="error" sx={{ mb: 2, maxWidth: 600, margin: '0 auto' }}>
          {errorMsg}
        </Alert>
      )}
      {successMsg && (
        <Alert severity="success" sx={{ mb: 2, maxWidth: 600, margin: '0 auto' }}>
          {successMsg}
        </Alert>
      )}

      <Box
        sx={{
          maxWidth: 600,
          margin: '0 auto',
          mb: 6,
          display: 'flex',
          flexDirection: { xs: 'column' },
          gap: 2,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {/* Removed client selector here */}
        <Button
          variant="contained"
          onClick={handleOpenModal}
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
            paddingX: 3,
            paddingY: 1
          }}
          disabled={clientIds.length === 0}
        >
          Create Contest
        </Button>
      </Box>

      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={(e, newValue) => {
            if (newValue !== null) setViewMode(newValue);
          }}
          aria-label="contest view mode"
        >
          <ToggleButton value="active" aria-label="active contests" sx={{ textTransform: 'none', fontWeight: 'bold' }}>
            Active Contests
          </ToggleButton>
          <ToggleButton value="archived" aria-label="archived contests" sx={{ textTransform: 'none', fontWeight: 'bold' }}>
            Archived Contests
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {filteredContests.length === 0 ? (
        <Typography variant="body1" sx={{ textAlign: 'center', color: 'text.secondary' }}>
          No {viewMode === 'active' ? 'active' : 'archived'} contests found.
        </Typography>
      ) : (
        <Grid container spacing={3}>
          {filteredContests.map((contest) => (
            <Grid item xs={12} sm={6} md={4} key={contest.id}>
              <Card
                sx={{
                  borderRadius: '12px',
                  backgroundColor: '#ffffff',
                  transition: 'box-shadow 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                  },
                  cursor: 'pointer',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
                onClick={() => handleCardClick(contest)}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {contest.title}
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  {contest.description && (
                    <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                      {contest.description}
                    </Typography>
                  )}
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                    Start: {format(contest.startDate.toDate(), 'MMM d, yyyy')}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    End: {format(contest.endDate.toDate(), 'MMM d, yyyy')}
                  </Typography>
                </CardContent>
                <Box sx={{ p: 2, pt: 0 }}>
                  <Button
                    variant="outlined"
                    onClick={(e) => handleEditClick(contest, e)}
                    sx={{ textTransform: 'none', fontWeight: 'bold' }}
                  >
                    Edit Settings
                  </Button>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <CreateContestModal
        open={modalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
        availableClients={clientIds.map(cid => ({ id: cid, name: cid }))}
        selectedContest={selectedContest}
      />

      <ContestDetailsModal
        open={detailsOpen}
        onClose={handleDetailsClose}
        contest={detailsContest}
      />
    </Box>
  );
};

export default ContestsPage;
