// src/components/Settings.js

import React, { useState, useContext } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Grid,
  Paper,
  Divider,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert,
} from '@mui/material';
import { Visibility, VisibilityOff, Email, Lock } from '@mui/icons-material';
import { AuthContext } from '../contexts/AuthContext';
import { updateEmail, updatePassword, sendEmailVerification } from 'firebase/auth';

const Settings = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { currentUser, reauthenticateUser } = useContext(AuthContext);

  const [email, setEmail] = useState(currentUser ? currentUser.email : '');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleUpdateEmail = async () => {
    if (!email) {
      setSnackbar({ open: true, message: 'Email cannot be empty.', severity: 'warning' });
      return;
    }

    if (!currentPassword) {
      setSnackbar({
        open: true,
        message: 'Current password is required to update email.',
        severity: 'warning',
      });
      return;
    }

    setIsSubmitting(true);

    try {
      // Re-authenticate user
      await reauthenticateUser(currentPassword);

      // Update email
      await updateEmail(currentUser, email);

      // Send email verification to new email
      await sendEmailVerification(currentUser);

      setSnackbar({
        open: true,
        message:
          'Email updated successfully. A verification email has been sent to your new address.',
        severity: 'success',
      });

      // Optionally, you might want to clear the currentPassword field
      setCurrentPassword('');
    } catch (error) {
      console.error('Error updating email:', error);

      let errorMessage = 'Failed to update email.';
      if (error.code === 'auth/invalid-email') {
        errorMessage = 'The email address is not valid.';
      } else if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'The email address is already in use by another account.';
      } else if (error.code === 'auth/wrong-password') {
        errorMessage = 'The current password is incorrect.';
      } else if (error.code === 'auth/operation-not-allowed') {
        errorMessage = 'Email updates are not allowed. Please contact support.';
      }

      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdatePassword = async () => {
    if (!currentPassword || !newPassword) {
      setSnackbar({
        open: true,
        message: 'Both current and new passwords are required.',
        severity: 'warning',
      });
      return;
    }

    setIsSubmitting(true);

    try {
      // Re-authenticate user
      await reauthenticateUser(currentPassword);

      // Update password
      await updatePassword(currentUser, newPassword);

      setSnackbar({
        open: true,
        message: 'Password updated successfully.',
        severity: 'success',
      });

      // Clear password fields
      setCurrentPassword('');
      setNewPassword('');
    } catch (error) {
      console.error('Error updating password:', error);

      let errorMessage = 'Failed to update password.';
      if (error.code === 'auth/weak-password') {
        errorMessage = 'The new password is too weak.';
      } else if (error.code === 'auth/wrong-password') {
        errorMessage = 'The current password is incorrect.';
      } else if (error.code === 'auth/requires-recent-login') {
        errorMessage = 'Please re-authenticate and try again.';
      }

      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: theme.spacing(4),
          maxWidth: 600,
          width: '100%',
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
          Account Settings
        </Typography>

        <Divider sx={{ marginY: theme.spacing(2) }} />

        <Grid container spacing={4}>
          {/* Update Email Section */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Update Email
            </Typography>
            <TextField
              label="New Email Address"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email color="action" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Current Password"
              variant="outlined"
              type={showCurrentPassword ? 'text' : 'password'}
              fullWidth
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              sx={{ marginTop: theme.spacing(2) }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock color="action" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={showCurrentPassword ? 'Hide password' : 'Show password'}
                      onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                      edge="end"
                    >
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: theme.spacing(2) }}
              onClick={handleUpdateEmail}
              disabled={isSubmitting}
              fullWidth
            >
              {isSubmitting ? 'Updating...' : 'Update Email'}
            </Button>
          </Grid>

          <Divider sx={{ width: '100%', marginY: theme.spacing(2) }} />

          {/* Update Password Section */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Update Password
            </Typography>
            <TextField
              label="New Password"
              variant="outlined"
              type={showNewPassword ? 'text' : 'password'}
              fullWidth
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock color="action" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={showNewPassword ? 'Hide password' : 'Show password'}
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: theme.spacing(2) }}
              onClick={handleUpdatePassword}
              disabled={isSubmitting}
              fullWidth
            >
              {isSubmitting ? 'Updating...' : 'Update Password'}
            </Button>
          </Grid>
        </Grid>

        {/* Snackbar for Notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Paper>
    </Box>
  );
};

export default Settings;
