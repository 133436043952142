// src/components/MagStripeWriter.js
import React, { useState, useEffect } from 'react';

const MagStripeWriter = () => {
  const [quantity, setQuantity] = useState('');
  const [status, setStatus] = useState('');
  const [lastCardNumber, setLastCardNumber] = useState(1000000001);

  // Load the last card number from localStorage on mount
  useEffect(() => {
    const storedLastCardNumber = localStorage.getItem('lastCardNumber');
    const initialCardNumber = 1000000001;
    if (storedLastCardNumber && parseInt(storedLastCardNumber, 10) >= initialCardNumber) {
      setLastCardNumber(parseInt(storedLastCardNumber, 10));
    } else {
      // Set to initialCardNumber if stored value is invalid
      localStorage.setItem('lastCardNumber', initialCardNumber);
      setLastCardNumber(initialCardNumber);
    }
  }, []);

  // Function to create and download .dat file
  const createDatFile = () => {
    const intQuantity = parseInt(quantity, 10);
    if (!intQuantity || isNaN(intQuantity) || intQuantity <= 0) {
      setStatus('Please enter a valid quantity');
      return;
    }

    try {
      let fileContent = '';
      const currentDate = new Date().toISOString().split('T')[0].replace(/-/g, '.');
      let cardNumber = lastCardNumber;

      for (let i = 0; i < intQuantity; i++) {
        const currentTime = new Date().toTimeString().split(' ')[0];
        const formattedTime = currentTime.substring(0, 8); // Keeping full HH:MM:SS format

        // Generate card number starting from lastCardNumber
        const formattedCardNumber = String(cardNumber);

        fileContent += `${currentDate}    ${formattedTime}\n`;
        fileContent += `%?\n`;
        fileContent += `;:<>${formattedCardNumber}?\n`;
        fileContent += `+?\n\n`;

        cardNumber++;
      }

      // Update the last card number in localStorage
      localStorage.setItem('lastCardNumber', cardNumber);
      setLastCardNumber(cardNumber);

      // Create a Blob object representing the .dat file
      const blob = new Blob([fileContent], { type: 'text/plain' });

      // Create a link element to download the file
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      // Include the card quantity in the file name
      link.download = `magstripe_data_${intQuantity}.dat`;
      document.body.appendChild(link);

      // Programmatically click the link to trigger download
      link.click();

      // Clean up and remove the link element
      document.body.removeChild(link);
      setStatus('File created and download triggered');
    } catch (error) {
      console.error('Error creating .dat file:', error);
      setStatus('Failed to create .dat file');
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>USB MagStripe Writer - .dat File Generator</h2>
      <p>Status: {status}</p>
      <div>
        <input
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          placeholder="Enter quantity of cards to generate"
        />
      </div>
      <button onClick={createDatFile} style={{ marginTop: '10px' }}>
        Create .dat File
      </button>
    </div>
  );
};

export default MagStripeWriter;
