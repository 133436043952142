// src/components/ClientDataProcessor.js
import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  addDoc, 
  Timestamp
} from 'firebase/firestore';
import { Box, Typography, CircularProgress, Button, TextField, Card, CardContent, Divider } from '@mui/material';
import Papa from 'papaparse';

// A simple hash function for creating a short hash from a string.
// This isn't cryptographically secure, but is sufficient for deduplication checks.
function generateHash(str) {
  let hash = 0, i, chr;
  if (str.length === 0) return hash.toString();
  for (i = 0; i < str.length; i++) {
    chr   = str.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  // Convert the integer hash to a hex string for consistency
  return hash.toString(16);
}

const getStoreIdFromEmails = (emails) => {
  const storeEmailRegex = /^Sonic(\d+)@inspirepartners\.net$/i;
  for (const email of emails) {
    const match = email.match(storeEmailRegex);
    if (match) {
      return match[1];
    }
  }
  return null;
};

const ClientDataProcessor = () => {
  const [loading, setLoading] = useState(true);
  const [clientsData, setClientsData] = useState([]);
  const [threshold, setThreshold] = useState(5); // default L2R threshold
  const [csvData, setCsvData] = useState(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const fetchSonicClients = async () => {
      try {
        setLoading(true);
        const clientsRef = collection(db, 'clients');
        const q = query(clientsRef, where('reviewPlatform', '==', 'Sonic'));
        const snap = await getDocs(q);

        const results = [];
        snap.forEach((doc) => {
          const data = doc.data();
          const clientId = doc.id; // The original document ID
          const contactEmails = data.contactEmails || [];
          const displayName = data.displayName || 'Unknown Client Name';

          // Exclude '1aCardHolder'
          if (displayName === '1aCardHolder') return;

          const storeId = getStoreIdFromEmails(contactEmails);

          results.push({
            clientId,
            contactEmails,
            displayName,
            storeId,
            csvEntries: [],
          });
        });

        setClientsData(results);
      } catch (error) {
        console.error('Error fetching Sonic clients:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSonicClients();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: false,
      complete: (results) => {
        const data = results.data;
        setCsvData(data);
      },
      error: (err) => console.error('CSV parse error:', err),
    });
  };

  const handleThresholdChange = (e) => {
    setThreshold(e.target.value);
  };

  const processCsvData = async () => {
    if (!csvData) {
      console.log('No CSV data to process.');
      return;
    }

    setProcessing(true);

    // Create a map for quick storeId -> client
    const clientMap = {};
    clientsData.forEach((client) => {
      if (client.storeId) {
        clientMap[client.storeId] = client;
      }
    });

    // CSV Structure:
    // A: StoreID
    // B: Store Address (not used)
    // C: All Comments
    // D: L2R
    // E: Survey Date
    const rows = csvData.slice(1); // skip header row if present

    rows.forEach((row) => {
      const storeID = row[0]; 
      const comment = row[2]; 
      const l2r = parseInt(row[3], 10); 
      const surveyDateStr = row[4]; 

      if (!storeID || isNaN(l2r)) return;

      if (clientMap[storeID] && l2r <= threshold) {
        clientMap[storeID].csvEntries.push({
          comment,
          surveyDateStr,
          l2r,
        });
      }
    });

    const feedbackRef = collection(db, 'feedback');

    for (const client of clientsData) {
      for (const entry of client.csvEntries) {
        let entryTimestamp = new Date(entry.surveyDateStr);
        if (isNaN(entryTimestamp.getTime())) {
          entryTimestamp = new Date(); 
        }

        const entryTimestampFirestore = Timestamp.fromDate(entryTimestamp);

        const feedbackHash = generateHash(entry.comment || '');

        // Check for duplicates using clientId, timestamp, and feedbackHash
        const duplicateQuery = query(
          feedbackRef,
          where('clientId', '==', client.clientId),
          where('timestamp', '==', entryTimestampFirestore),
          where('feedbackHash', '==', feedbackHash)
        );

        const duplicateSnap = await getDocs(duplicateQuery);
        if (!duplicateSnap.empty) continue;

        // Add new doc using original client doc ID as clientId
        const feedbackDoc = {
          assignedTo: 'OAfeedback',
          clientId: client.clientId, // Original client doc ID
          contactEmails: client.contactEmails,
          displayName: client.displayName,
          feedback: entry.comment || '',
          feedbackHash, // Include hash for deduplication
          isRead: false,
          timestamp: entryTimestampFirestore,
          l2r: entry.l2r
        };

        await addDoc(feedbackRef, feedbackDoc);
      }
    }

    console.log('Feedback documents submitted without duplicates.');
    setProcessing(false);
  };

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', mt: 6 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
      <Card 
        sx={{ 
          width: '100%', 
          maxWidth: 500, 
          borderRadius: '12px', 
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)' 
        }}
      >
        <CardContent>
          <Typography 
            variant="h5" 
            sx={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif', mb: 2 }}
          >
            Client Data Processor
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <Typography variant="body1" sx={{ mb: 2 }}>
            Upload your CSV file and set the L2R threshold. The system will process the entries and submit
            them as feedback to Firestore, avoiding duplicates.
          </Typography>

          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: '500' }}>
            L2R Threshold (1-5)
          </Typography>
          <TextField
            type="number"
            inputProps={{ min: 1, max: 5 }}
            value={threshold}
            onChange={handleThresholdChange}
            sx={{ mb: 3, width: '100px' }}
          />

          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: '500' }}>
            Select CSV File
          </Typography>
          <input type="file" accept=".csv" onChange={handleFileChange} style={{ marginBottom: '24px' }} />

          <Button 
            variant="contained" 
            onClick={processCsvData} 
            disabled={!csvData || processing}
            sx={{ width: '100%', py: 1.5 }}
          >
            {processing ? 'Processing...' : 'Process CSV'}
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ClientDataProcessor;
