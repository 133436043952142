// src/components/RecentActivityCard.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CardActionArea,
  useTheme,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaClipboardList } from 'react-icons/fa';
import { auth, db } from '../firebase';
import {
  doc,
  getDoc,
  collectionGroup,
  query,
  where,
  getDocs,
  collection,
  orderBy
} from 'firebase/firestore';

const RecentActivityCard = () => {
  const [loading, setLoading] = useState(true);
  const [recentCount, setRecentCount] = useState(0);
  const [errorMsg, setErrorMsg] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecentActivity = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          throw new Error('User not authenticated');
        }

        const uid = currentUser.uid;

        // Fetch user data to get clientIds
        const userDocRef = doc(db, 'users', uid);
        const userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) {
          throw new Error('User data not found');
        }

        const userData = userDocSnap.data();
        const clientIds = userData.clientIds || [];
        console.log('Clients:', clientIds);

        if (clientIds.length === 0) {
          console.log('No clientIds found for this user. No recent activity.');
          setRecentCount(0);
          setLoading(false);
          return;
        }

        // Prepare a time cutoff (24 hours ago)
        const cutoff = new Date(Date.now() - 24 * 60 * 60 * 1000);
        console.log('Cutoff:', cutoff.toISOString());

        console.log('Running test query to fetch all responses without filters.');
        const testSnap = await getDocs(collectionGroup(db, 'responses'));
        console.log(`Test query returned ${testSnap.size} documents total.`);
        testSnap.forEach((d) => {
          console.log('Test Doc:', d.id, d.data());
        });

        if (testSnap.size === 0) {
          console.log(
            'No responses found at all (unfiltered). Either no responses exist or the subcollection name is incorrect.'
          );
          setRecentCount(0);
          setLoading(false);
          return;
        }

        console.log('Proceeding with filtered queries using clientId and timestamp...');
        let totalRecentCount = 0;

        // Firestore 'in' queries can handle up to 10 values
        const chunks = [];
        for (let i = 0; i < clientIds.length; i += 10) {
          chunks.push(clientIds.slice(i, i + 10));
        }
        console.log('Chunks:', chunks);

        // Query responses for these clients in the last 24 hours
        const queries = chunks.map((chunk, idx) => {
          console.log(`Creating query for chunk ${idx}:`, chunk);
          const responsesQuery = query(
            collectionGroup(db, 'responses'),
            where('clientId', 'in', chunk),
            where('timestamp', '>', cutoff)
          );
          return getDocs(responsesQuery).then((snap) => {
            console.log(`Responses chunk ${idx} returned ${snap.size} documents.`);
            return snap.size;
          });
        });

        const responsesCounts = await Promise.all(queries);
        const responsesTotal = responsesCounts.reduce((a, b) => a + b, 0);
        console.log('Total recent responses found:', responsesTotal);
        totalRecentCount += responsesTotal;

        // Now query OAfeedback for these clients in the last 24 hours
        const feedbackRef = collection(db, 'feedback');

        const oaQueries = chunks.map((chunk, idx) => {
          console.log(`Creating OAfeedback query for chunk ${idx}:`, chunk);
          const oaFeedbackQuery = query(
            feedbackRef,
            where('clientId', 'in', chunk),
            where('assignedTo', '==', 'OAfeedback'),
            where('timestamp', '>', cutoff),
            orderBy('timestamp', 'asc')
          );
          return getDocs(oaFeedbackQuery).then((snap) => {
            console.log(`OAfeedback chunk ${idx} returned ${snap.size} documents.`);
            return snap.size;
          });
        });

        const oaCounts = await Promise.all(oaQueries);
        const oaTotal = oaCounts.reduce((a, b) => a + b, 0);
        console.log('Total recent OAfeedback found:', oaTotal);
        totalRecentCount += oaTotal;

        setRecentCount(totalRecentCount);
      } catch (error) {
        console.error('Error fetching recent activity:', error);
        setErrorMsg('An error occurred while fetching recent activity.');
      } finally {
        setLoading(false);
      }
    };

    fetchRecentActivity();
  }, []);

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        style={{ flex: '1 1 300px' }}
      >
        <Box
          sx={{
            backgroundColor: '#ffffff',
            padding: 3,
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </motion.div>
    );
  }

  if (errorMsg) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        style={{ flex: '1 1 300px' }}
      >
        <Box
          sx={{
            backgroundColor: '#ffffff',
            padding: 3,
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            height: '100%',
          }}
        >
          <Alert severity="error">{errorMsg}</Alert>
        </Box>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
      style={{ flex: '1 1 300px' }}
    >
      <CardActionArea
        onClick={() => navigate('/activity')}
        sx={{
          backgroundColor: '#ffffff',
          padding: theme.spacing(3),
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <FaClipboardList size={48} color={theme.palette.primary.main} />
          <Typography variant="h6" sx={{ fontWeight: 'bold', ml: 2 }}>
            Recent Activity
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ flexGrow: 1 }}>
          {recentCount > 0
            ? `You have ${recentCount} new response${
                recentCount !== 1 ? 's' : ''
              } in the last 24 hours.`
            : 'No recent activity in the last 24 hours.'}
        </Typography>
        <Box sx={{ textAlign: 'right', mt: 2 }}>
          <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
            View Details
          </Typography>
        </Box>
      </CardActionArea>
    </motion.div>
  );
};

export default RecentActivityCard;
