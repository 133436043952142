import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Typography,
  CardActionArea,
  useTheme,
  CircularProgress,
  Badge,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaBell } from 'react-icons/fa';
import { auth, db } from '../firebase';
import { collection, doc, getDoc, query, where, onSnapshot } from 'firebase/firestore';

const NotificationsCard = () => {
  const [loading, setLoading] = useState(true);
  const [unreadCount, setUnreadCount] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();

  const unsubscribeRefs = useRef([]);
  const prevUnreadCount = useRef(0);
  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const setupListeners = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          throw new Error('User not authenticated');
        }

        const uid = currentUser.uid;
        const userDocRef = doc(db, 'users', uid);
        const userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) {
          throw new Error('User data not found');
        }

        const userData = userDocSnap.data();
        const clientIds = userData.clientIds || [];

        if (clientIds.length === 0) {
          if (isMounted) {
            setUnreadCount(0);
            setLoading(false);
          }
          return;
        }

        const chunks = [];
        for (let i = 0; i < clientIds.length; i += 10) {
          chunks.push(clientIds.slice(i, i + 10));
        }

        const feedbackCollectionRef = collection(db, 'feedback');
        let chunkCounts = Array(chunks.length).fill(0);

        unsubscribeRefs.current.forEach((unsub) => unsub());
        unsubscribeRefs.current = [];

        chunks.forEach((chunk, index) => {
          // We only want OAfeedback items that are unread (isRead == false) and have l2r <= 3
          const feedbackQuery = query(
            feedbackCollectionRef,
            where('clientId', 'in', chunk),
            where('assignedTo', '==', 'OAfeedback'),
            where('isRead', '==', false),
            where('l2r', '<=', 3)
          );

          const unsubscribe = onSnapshot(feedbackQuery, (snapshot) => {
            chunkCounts[index] = snapshot.size;
            const newTotal = chunkCounts.reduce((a, b) => a + b, 0);

            if (isMounted) {
              if (newTotal > prevUnreadCount.current) {
                setHighlight(true);
                setTimeout(() => setHighlight(false), 1000);
              }

              prevUnreadCount.current = newTotal;
              setUnreadCount(newTotal);
              setLoading(false);
            }
          });

          unsubscribeRefs.current.push(unsubscribe);
        });

        if (chunks.length === 0 && isMounted) {
          setLoading(false);
        }
      } catch (error) {
        console.error('Error setting up notification listeners:', error);
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    setupListeners();

    return () => {
      isMounted = false;
      unsubscribeRefs.current.forEach((unsub) => unsub());
    };
  }, []);

  const badgeStyles = {
    '& .MuiBadge-badge': {
      backgroundColor: '#f44336',
      color: '#fff',
      fontWeight: 'bold',
      borderRadius: '50%',
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      animation: 'pulse 1s infinite ease-in-out',
    },
    '@keyframes pulse': {
      '0%': { transform: 'scale(1)' },
      '50%': { transform: 'scale(1.2)' },
      '100%': { transform: 'scale(1)' },
    },
  };

  const highlightStyles = {
    transition: 'transform 0.5s ease, box-shadow 0.5s ease',
    transform: highlight ? 'scale(1.2)' : 'scale(1)',
    // Increase intensity and remove default box-shadow when highlighting
    boxShadow: highlight ? '0 0 30px 10px rgba(255, 0, 0, 0.7)' : '0 4px 12px rgba(0,0,0,0.1)',
    position: 'relative',
    overflow: 'visible',
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
      style={{ flex: '1 1 300px', overflow: 'visible' }}
    >
      <CardActionArea
        onClick={() => navigate('/notifications')}
        sx={{
          ...highlightStyles,
          backgroundColor: '#ffffff',
          padding: theme.spacing(3),
          borderRadius: '12px',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', position: 'relative' }}>
          <Badge
            badgeContent={unreadCount > 0 ? unreadCount : null}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            sx={badgeStyles}
          >
            <FaBell size={48} color={theme.palette.primary.main} />
          </Badge>
          <Typography variant="h6" sx={{ fontWeight: 'bold', ml: 2 }}>
            Notifications
          </Typography>
        </Box>
        {loading ? (
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Typography variant="body1" sx={{ flexGrow: 1 }}>
            You have {unreadCount} new notification{unreadCount !== 1 ? 's' : ''}.
          </Typography>
        )}
        <Box sx={{ textAlign: 'right', mt: 2 }}>
          <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
            View Details
          </Typography>
        </Box>
      </CardActionArea>
    </motion.div>
  );
};

export default NotificationsCard;
