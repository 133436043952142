import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  useTheme
} from '@mui/material';
import { format } from 'date-fns';
import { collection, collectionGroup, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../firebase';

const ContestDetailsModal = ({ open, onClose, contest }) => {
  const theme = useTheme();
  const [loadingLeaderboard, setLoadingLeaderboard] = useState(false);
  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      if (!contest || !open) return;

      setLoadingLeaderboard(true);

      try {
        // Determine client IDs
        let clientIds = [];
        if (Array.isArray(contest.clientIds)) {
          clientIds = contest.clientIds;
        } else if (contest.clientId) {
          clientIds = [contest.clientId];
        }

        // If no client IDs at all, just skip
        if (clientIds.length === 0) {
          setLeaderboard([]);
          return;
        }

        const start = contest.startDate.toDate();
        const end = contest.endDate.toDate();

        // Fetch tags (employees) for all clients and build a combined tagMap
        const tagMap = {};
        for (const cid of clientIds) {
          const tagsRef = collection(db, 'clients', cid, 'nfcTags');
          const tagsQ = query(tagsRef, where('placement', '==', 'Person'));
          const tagsSnap = await getDocs(tagsQ);

          tagsSnap.forEach(docSnap => {
            const data = docSnap.data();
            if (data.assignedTo && data.id) {
              tagMap[data.id] = data.assignedTo;
            }
          });
        }

        // Fetch responses for all clients and combine them
        const allResponses = [];
        for (const cid of clientIds) {
          const responsesRef = collectionGroup(db, 'responses');
          const responsesQ = query(
            responsesRef,
            where('clientId', '==', cid),
            where('timestamp', '>=', start),
            where('timestamp', '<=', end),
            orderBy('timestamp', 'asc')
          );

          const responsesSnap = await getDocs(responsesQ);
          responsesSnap.forEach(respDoc => {
            allResponses.push(respDoc);
          });
        }

        // Count responses per employee from combined data
        const counts = {};
        allResponses.forEach(respDoc => {
          const data = respDoc.data();
          const parentRef = respDoc.ref.parent.parent;
          const tagId = parentRef ? parentRef.id : null;

          if (tagId && tagMap[tagId]) {
            const empName = tagMap[tagId];
            if (contest.ignoreList && contest.ignoreList.includes(empName)) {
              return; // Skip ignored employees
            }
            counts[empName] = (counts[empName] || 0) + 1;
          }
        });

        const leaderboardArr = Object.entries(counts).map(([name, value]) => ({ name, value }));
        leaderboardArr.sort((a, b) => b.value - a.value);

        const top3 = leaderboardArr.slice(0, 3);
        setLeaderboard(top3);
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
        setLeaderboard([]);
      } finally {
        setLoadingLeaderboard(false);
      }
    };

    fetchLeaderboard();
  }, [contest, open]);

  if (!contest) return null;

  let baseUrl = 'https://truemetric.io';
  if (window.location.hostname === '208.109.244.166') {
    baseUrl = `http://${window.location.hostname}:3005`;
  }

  const hashedPassword = contest.hashedPassword;
  const leaderboardUrl = hashedPassword
    ? `${baseUrl}/leaderboard?contestId=${contest.id}&pw=${encodeURIComponent(hashedPassword)}`
    : '';

  const handleOpenLeaderboard = () => {
    if (leaderboardUrl) {
      window.open(leaderboardUrl, '_blank');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
          overflow: 'hidden',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        }
      }}
    >
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          fontFamily: 'Montserrat, sans-serif',
          backgroundColor: theme.palette.primary.main,
          color: '#ffffff',
          py: 2,
          px: 3
        }}
      >
        Contest Details
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          background: `linear-gradient(135deg, ${theme.palette.grey[50]} 0%, ${theme.palette.grey[100]} 100%)`,
          py: 3,
          px: 3
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            fontFamily: 'Montserrat, sans-serif',
            mb: 2
          }}
        >
          {contest.title}
        </Typography>

        {contest.description && (
          <Typography variant="body2" sx={{ mb: 3, color: 'text.secondary' }}>
            {contest.description}
          </Typography>
        )}

        <Box sx={{ mb: 3 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
            <strong>Start:</strong> {format(contest.startDate.toDate(), 'MMM d, yyyy')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>End:</strong> {format(contest.endDate.toDate(), 'MMM d, yyyy')}
          </Typography>
        </Box>

        {(contest.contestType ||
          contest.contestFocus ||
          contest.totalSurveysOption ||
          (contest.ignoreList && contest.ignoreList.length > 0)) && (
          <Box sx={{ mb: 3 }}>
            {contest.contestType && (
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Type:</strong> {contest.contestType}
              </Typography>
            )}
            {contest.contestFocus && (
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Focus:</strong> {contest.contestFocus}
              </Typography>
            )}
            {contest.totalSurveysOption && (
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Surveys Option:</strong> {contest.totalSurveysOption === 'overall' ? 'Overall' : 'Per Hour'}
              </Typography>
            )}
            {contest.ignoreList && contest.ignoreList.length > 0 && (
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Ignored Employees:</strong> {contest.ignoreList.join(', ')}
              </Typography>
            )}
          </Box>
        )}

        <Typography
          variant="h6"
          sx={{
            mt: 2,
            mb: 2,
            fontWeight: 'bold',
            fontFamily: 'Montserrat, sans-serif'
          }}
        >
          Leaderboard (Top 3)
        </Typography>

        {loadingLeaderboard ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : leaderboard.length === 0 ? (
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            No responses found.
          </Typography>
        ) : (
          <List>
            {leaderboard.map((entry, index) => (
              <ListItem key={index} disableGutters>
                <ListItemText
                  primary={
                    <Typography variant="body2" sx={{ fontWeight: '500' }}>
                      {index + 1}. {entry.name}: {entry.value}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, backgroundColor: theme.palette.grey[100] }}>
        {hashedPassword && (
          <Button
            onClick={handleOpenLeaderboard}
            variant="outlined"
            sx={{ textTransform: 'none', fontWeight: 'bold', mr: 2 }}
          >
            View Full Leaderboard
          </Button>
        )}
        <Button
          onClick={onClose}
          variant="contained"
          sx={{ textTransform: 'none', fontWeight: 'bold' }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContestDetailsModal;
