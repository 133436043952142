// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from 'firebase/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); // To handle initial loading state

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Function to handle email/password login
  const login = (email, password, rememberMe) => {
    // Optionally handle 'remember me' functionality here
    return signInWithEmailAndPassword(auth, email, password);
  };

  // Function to handle logout
  const logout = () => {
    return signOut(auth);
  };

  // Function to handle Google sign-in
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    // Optionally add scopes or custom parameters
    return signInWithPopup(auth, provider);
  };

  // Function to re-authenticate user
  const reauthenticateUser = (currentPassword) => {
    if (!currentUser || !currentUser.email) {
      throw new Error('No authenticated user found.');
    }
    const credential = EmailAuthProvider.credential(
      currentUser.email,
      currentPassword
    );
    return reauthenticateWithCredential(currentUser, credential);
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        login,
        logout,
        signInWithGoogle,
        reauthenticateUser, // Expose reauthenticateUser
      }}
    >
      {!loading && children} {/* Render children only after auth state is determined */}
    </AuthContext.Provider>
  );
};
