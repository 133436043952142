// src/firebase.js

import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';

// Your Firebase configuration object
const firebaseConfig = {
  apiKey: 'AIzaSyC7PlMLKTDiK1aG3gNlgWRQ20tdWNCl_VM',
  authDomain: 'truemetric-cffb7.firebaseapp.com',
  projectId: 'truemetric-cffb7',
  storageBucket: 'truemetric-cffb7.appspot.com',
  messagingSenderId: '30715104344',
  appId: '1:30715104344:web:adbc2077f383ae7129ce2d',
  measurementId: 'G-6CWSBQ378G',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

// Function to create user document
const createUserDocument = async (user) => {
  const userDocRef = doc(db, 'users', user.uid);
  const userDocSnap = await getDoc(userDocRef);

  if (!userDocSnap.exists()) {
    // Create the user document with initial data
    await setDoc(userDocRef, {
      uid: user.uid,
      email: user.email,
      clientIds: [], // Initialize with an empty array or assign clients as needed
      createdAt: serverTimestamp(),
      // Add other fields as needed
    });
  }
};

// Listen for auth state changes
onAuthStateChanged(auth, (user) => {
  if (user) {
    createUserDocument(user);
  }
});

export { db, auth, signInWithEmailAndPassword, createUserWithEmailAndPassword };
