// src/components/ApologyPage.js

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Alert,
} from '@mui/material';
import { db } from '../firebase';
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';
import axios from 'axios';

const ApologyPage = () => {
  const location = useLocation();
  const { apologyMessage, tagId } = location.state || {};

  // Determine base URL based on hostname
  const baseUrl = window.location.hostname.includes('truemetric.io')
    ? 'https://truemetric.io'
    : 'http://208.109.244.166:5005';

  // State variables for feedback and contact information
  const [feedback, setFeedback] = useState('');
  const [requestContact, setRequestContact] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  // State variables for clientId, clientData, contactEmails, assignedTo, and displayName
  const [clientId, setClientId] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [contactEmails, setContactEmails] = useState([]);
  const [assignedTo, setAssignedTo] = useState(null);
  const [clientDisplayName, setClientDisplayName] = useState(null);

  // State variables for submission status and errors
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // State variables for validation errors
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  // useEffect to fetch clientId (and assignedTo) when tagId changes
  useEffect(() => {
    if (tagId) {
      const fetchClientId = async () => {
        try {
          const nfcTagDocRef = doc(db, 'nfcTags', tagId);
          const nfcTagDocSnap = await getDoc(nfcTagDocRef);
          if (nfcTagDocSnap.exists()) {
            const nfcTagData = nfcTagDocSnap.data();
            const clientId = nfcTagData.clientId;
            setClientId(clientId);
            setAssignedTo(nfcTagData.assignedTo || null);
            console.log('Client ID:', clientId); // Console log the clientId
            console.log('Assigned To:', nfcTagData.assignedTo);
          } else {
            console.log('No such nfcTag document!');
          }
        } catch (error) {
          console.error('Error fetching clientId:', error);
        }
      };

      fetchClientId();
    }
  }, [tagId]);

  // useEffect to fetch clientData (including displayName) when clientId changes
  useEffect(() => {
    if (clientId) {
      const fetchClientData = async () => {
        try {
          const clientDocRef = doc(db, 'clients', clientId);
          const clientDocSnap = await getDoc(clientDocRef);
          if (clientDocSnap.exists()) {
            const clientData = clientDocSnap.data();
            setClientData(clientData);

            // Handle contact emails (single or multiple)
            const emails =
              clientData.contactEmails ||
              (clientData.contactEmail ? [clientData.contactEmail] : []);
            setContactEmails(emails);
            setClientDisplayName(clientData.displayName || null);

            // Log the relevant information
            console.log('Client Display Name:', clientData.displayName);
            console.log('Contact Emails:', emails);
            console.log('Apology Message:', clientData.apologyMessage);
          } else {
            console.log('No client document found!');
          }
        } catch (error) {
          console.error('Error fetching client data:', error);
        }
      };

      fetchClientData();
    }
  }, [clientId]);

  // Handler for form submission
  const handleSubmit = async () => {
    // Prevent submission if feedback is empty
    if (feedback.trim() === '') return;

    // Reset previous errors
    setSubmitError(null);
    setEmailError(false);
    setPhoneError(false);

    // Basic email validation if email is provided
    if (email.trim() !== '') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email.trim())) {
        setEmailError(true);
        return;
      }
    }

    // Basic phone validation if phone is provided (e.g., only numbers and optional +)
    if (phone.trim() !== '') {
      const phoneRegex = /^\+?\d{7,15}$/;
      if (!phoneRegex.test(phone.trim())) {
        setPhoneError(true);
        return;
      }
    }

    setIsSubmitting(true);

    try {
      // Prepare the feedback data
      const feedbackData = {
        feedback,
        timestamp: new Date(),
        tagId: tagId || null,  // Include tagId if available
        clientId: clientId || null,  // Include clientId if available
        contactEmails: contactEmails || [],
        assignedTo: assignedTo || null,
        displayName: clientDisplayName || null,
		isRead: false,
      };

      // If user requested contact, include additional fields
      if (requestContact) {
        feedbackData.name = name.trim() !== '' ? name.trim() : null;
        feedbackData.phone = phone.trim() !== '' ? phone.trim() : null;
        feedbackData.email = email.trim() !== '' ? email.trim() : null;
      }

      // Add the feedback document to Firestore
      await addDoc(collection(db, 'feedback'), feedbackData);

      // Send the feedback data to the Express server
      await axios.post(`${baseUrl}/api/feedback`, feedbackData);

      setSubmitted(true);

      // Optionally, clear the form fields
      setFeedback('');
      setName('');
      setPhone('');
      setEmail('');
      setRequestContact(false);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setSubmitError(
        'There was an error submitting your feedback. Please try again later.'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container
      sx={{ textAlign: 'center', marginTop: '50px', maxWidth: '600px' }}
    >
      <Typography variant="h5" gutterBottom>
        {clientData?.apologyMessage ||
          apologyMessage ||
          "We're sorry to hear that."}
      </Typography>
      {!submitted ? (
        <Box
          sx={{
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          {/* Feedback Input */}
          <TextField
            label="Your Feedback"
            multiline
            rows={4}
            variant="outlined"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            fullWidth
            required
          />

          {/* Error Alert */}
          {submitError && <Alert severity="error">{submitError}</Alert>}

          {/* Toggle Contact Request */}
          <Button
            variant="text"
            color="secondary"
            onClick={() => setRequestContact(!requestContact)}
            size="small"
          >
            {requestContact ? 'Cancel Contact Request' : 'Request Contact'}
          </Button>

          {/* Contact Information Inputs */}
          {requestContact && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                textAlign: 'left',
              }}
            >
              <TextField
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
              <TextField
                label="Phone"
                variant="outlined"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
                error={phoneError}
                helperText={phoneError ? 'Please enter a valid phone number.' : ''}
              />
              <TextField
                label="Email"
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                error={emailError}
                helperText={emailError ? 'Please enter a valid email address.' : ''}
              />
            </Box>
          )}

          {/* Submit Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={feedback.trim() === '' || isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
          </Button>
        </Box>
      ) : (
        <Typography variant="h6" sx={{ marginTop: '30px' }}>
          Thank you for your feedback!
        </Typography>
      )}
    </Container>
  );
};

export default ApologyPage;
