// src/components/MainLayout.js

import React, { useContext } from 'react';
import {
  Box,
  CssBaseline,
  Toolbar,
  AppBar,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Menu as MenuIcon } from '@mui/icons-material';
import SideNav from './SideNav';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const MainLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (err) {
      console.error('Failed to log out:', err);
    }
  };

  const drawerWidth = 260;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* AppBar for Mobile */}
      {isMobile && (
        <AppBar
          position="fixed"
          sx={{ zIndex: theme.zIndex.drawer + 1, background: '#0f2027' }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
              Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
      )}

      {/* Sidebar */}
      <SideNav
        handleDrawerToggle={handleDrawerToggle}
        handleLogout={handleLogout}
        drawerWidth={drawerWidth}
        mobileOpen={mobileOpen}
        isMobile={isMobile}
      />

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: theme.spacing(3),
          marginLeft: isMobile ? 0 : `${drawerWidth}px`,
          backgroundColor: '#f0f2f5',
          minHeight: '100vh',
        }}
      >
        {/* Adjust the top padding for mobile when AppBar is present */}
        {isMobile && <Toolbar />}

        {/* Outlet for rendering child routes */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
