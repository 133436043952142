// src/components/Login.js

import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import {
  Box,
  Button,
  Image, // Import Image for the logo
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  FormControl,
  FormLabel,
  Text,
  IconButton,
  useToast,
  useMediaQuery,
  chakra,
  Checkbox,
  Divider,
  VStack,
  HStack, // Ensure HStack is imported
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon, EmailIcon, LockIcon, AtSignIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { motion, isValidMotionProp } from 'framer-motion';
import { FaGoogle } from 'react-icons/fa'; // Ensure this is installed

// Import your logo image
import icononly from './images/icononly.png'; // Adjust the path based on your project structure

const MotionBox = chakra(motion.div, {
  /**
   * Allow motion props and Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) ||
    [
      'children',
      'width',
      'height',
      'bg',
      'bgGradient',
      'position',
      'zIndex',
      'top',
      'left',
      'right',
      'bottom',
      'display',
      'alignItems',
      'justifyContent',
      'p',
      'overflow',
      'rounded',
      'boxShadow',
      'maxW',
      'initial',
      'animate',
      'transition',
      'mb',
      'textAlign',
      'fontSize',
      'color',
      'textShadow',
      'onSubmit',
      'id',
      'isRequired',
      'type',
      'placeholder',
      'variant',
      'value',
      'onChange',
      'focusBorderColor',
      'aria-label',
      'icon',
      'onClick',
      'size',
      'colorScheme',
      '_hover',
    ].includes(prop),
});

const Login = () => {
  const { login, signInWithGoogle } = useContext(AuthContext); // Destructure signInWithGoogle
  const navigate = useNavigate();
  const toast = useToast();
  const [isMobile] = useMediaQuery('(max-width: 600px)');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password, rememberMe);
      navigate('/dashboard');
    } catch (err) {
      toast({
        title: 'Login failed.',
        description: 'Invalid email or password.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error(err);
    }
  };

  const handleSocialLogin = async (provider) => {
    try {
      await signInWithGoogle();
      toast({
        title: 'Login Successful',
        description: 'You have successfully logged in with Google.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate('/dashboard');
    } catch (error) {
      console.error(`Error signing in with ${provider}:`, error);
      toast({
        title: 'Login failed.',
        description: `Failed to login with ${provider}. Please try again.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      minH="100vh"
      bgGradient="linear(to-r, gray.700, gray.900)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      p="4"
      position="relative"
      overflow="hidden"
    >
      {/* Overlay for better readability */}
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg="blackAlpha.600"
        zIndex="0"
      />

      {/* Login Form */}
      <MotionBox
        bg="rgba(255, 255, 255, 0.1)"
        backdropFilter="blur(10px)"
        borderRadius="lg"
        boxShadow="2xl"
        zIndex="1"
        maxW="md"
        width="100%"
        p={isMobile ? '6' : '8'}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
      >
        <VStack spacing="6" align="stretch">
          {/* Logo and Title */}
          <Box textAlign="center">
            <Image
              src={icononly}
              alt="TrueMetric.io Logo"
              boxSize={isMobile ? '80px' : '120px'} // Reduced size
              objectFit="contain"
              mx="auto" // Centers the image horizontally
            />
            <Text
              bgGradient="linear(to-r, white, blue.500, purple.400)" // Flipped gradient
              bgClip="text"
              fontSize={isMobile ? 'xl' : '2xl'}
              fontWeight="semibold"
              mt="2"
              textAlign="center"
            >
              TrueMetric.io
            </Text>
          </Box>

          <form onSubmit={handleSubmit}>
            <FormControl id="email" mb="4" isRequired>
              <FormLabel color="whiteAlpha.800">Email</FormLabel> {/* Updated color */}
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <EmailIcon color="orange.400" />
                </InputLeftElement>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  variant="filled"
                  bg="whiteAlpha.300"
                  _hover={{ bg: 'whiteAlpha.400' }}
                  _focus={{ bg: 'whiteAlpha.400' }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  focusBorderColor="orange.400"
                  borderRadius="md"
                />
              </InputGroup>
            </FormControl>

            <FormControl id="password" mb="4" isRequired>
              <FormLabel color="whiteAlpha.800">Password</FormLabel> {/* Updated color */}
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <LockIcon color="orange.400" />
                </InputLeftElement>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter your password"
                  variant="filled"
                  bg="whiteAlpha.300"
                  _hover={{ bg: 'whiteAlpha.400' }}
                  _focus={{ bg: 'whiteAlpha.400' }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  focusBorderColor="orange.400"
                  borderRadius="md"
                />
                <InputRightElement>
                  <IconButton
                    variant="ghost"
                    colorScheme="orange"
                    aria-label={showPassword ? 'Hide password' : 'Show password'}
                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl display="flex" alignItems="center" mb="4">
              <Checkbox
                isChecked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                colorScheme="orange"
              >
                Remember me
              </Checkbox>
              <Text
                ml="auto"
                color="orange.300"
                cursor="pointer"
                onClick={() => navigate('/forgot-password')}
                _hover={{ textDecoration: 'underline' }}
              >
                Forgot password?
              </Text>
            </FormControl>

            <Button
              type="submit"
              colorScheme="orange"
              size="lg"
              width="full"
              mb="4"
              _hover={{
                bg: 'orange.500',
                transform: 'scale(1.05)',
              }}
              leftIcon={<AtSignIcon />}
              transition="all 0.3s ease"
            >
              Log In
            </Button>
          </form>

          <Divider />

          <Text textAlign="center" color="gray.300">
            Or continue with
          </Text>

          <HStack spacing="4" justify="center">
            <IconButton
              aria-label="Login with Google"
              icon={<FaGoogle />}
              colorScheme="red"
              variant="outline"
              size="lg"
              onClick={() => handleSocialLogin('Google')}
              _hover={{ bg: 'red.500', color: 'white' }}
            />
            {/* Removed GitHub IconButton */}
          </HStack>

          {/* Removed Sign-Up Section */}
        </VStack>
      </MotionBox>
    </Box>
  );
};

export default Login;
