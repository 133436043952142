// src/components/SideNav.js

import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext'; // Import AuthContext
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FaSignOutAlt, FaHome, FaChartLine, FaCog, FaTags, FaFlag } from 'react-icons/fa'; // FaFlag for contests icon
import { motion } from 'framer-motion';
import icononly from './images/icononly.png'; // Ensure your logo path is correct

const SideNav = ({ handleDrawerToggle, handleLogout, drawerWidth, mobileOpen, isMobile }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext); // Get currentUser from context

  const navItems = [
    { text: 'Home', icon: <FaHome size={20} />, route: '/dashboard' },
    // Conditionally include MagStripe Writer for a specific UID
    ...(currentUser && currentUser.uid === 'YHHq95EEzIgXUcnFzehvM4GCAMH3'
      ? [{ text: 'MagStripe Writer', icon: <FaChartLine size={20} />, route: '/magstripe-writer' }]
      : []),  
	{ text: 'Contests', icon: <FaFlag size={20} />, route: '/contests' }, // New Nav Item for Contests
	
	    ...(currentUser && currentUser.uid === 'YHHq95EEzIgXUcnFzehvM4GCAMH3'
      ? [{ text: 'Client Data', icon: <FaChartLine size={20} />, route: '/client-data' }]
      : []),
	
	{ text: 'Manage Tags', icon: <FaTags size={20} />, route: '/manage-tags' },
	
    { text: 'Settings', icon: <FaCog size={20} />, route: '/settings' },
    
    // Add more items as needed
  ];

  const drawer = (
    <Box
      sx={{
        width: drawerWidth,
        height: '100%',
        background: 'linear-gradient(180deg, #0f2027, #203a43, #2c5364)',
        color: '#ffffff',
        overflow: 'hidden',
      }}
    >
      {/* Logo and Title */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: theme.spacing(4, 2),
          textAlign: 'center',
        }}
      >
        <motion.img
          src={icononly}
          alt="TrueMetric Logo"
          style={{ width: '25%', marginBottom: theme.spacing(1) }}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
        />
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}
        >
          TrueMetric.io
        </Typography>
      </Box>

      <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', margin: theme.spacing(0, 0) }} />

      {/* Navigation Items */}
      <List sx={{ marginTop: theme.spacing(0) }}>
        {navItems.map((item, index) => (
          <motion.div
            key={item.text}
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.1 * index }}
          >
            <ListItem
              button
              onClick={() => navigate(item.route)}
              sx={{
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                padding: theme.spacing(1.5, 2),
              }}
            >
              <ListItemIcon sx={{ color: '#ffffff', minWidth: '40px' }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{ variant: 'body1', fontWeight: '500' }}
              />
            </ListItem>
          </motion.div>
        ))}
      </List>

      {/* Log Out Button at the Bottom */}
      <Box sx={{ flexGrow: 1 }} />
      <Divider
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          margin: theme.spacing(0, 2, 0, 2),
        }}
      />
      <ListItem
        button
        onClick={handleLogout}
        sx={{
          color: '#ffffff',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
          padding: theme.spacing(1.5, 2),
        }}
      >
        <ListItemIcon sx={{ color: '#ffffff', minWidth: '40px' }}>
          <FaSignOutAlt size={20} />
        </ListItemIcon>
        <ListItemText
          primary="Log Out"
          primaryTypographyProps={{ variant: 'body1', fontWeight: '500' }}
        />
      </ListItem>
    </Box>
  );

  return (
    <React.Fragment>
      {/* Drawer */}
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            border: 'none',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        }}
      >
        {drawer}
      </Drawer>
    </React.Fragment>
  );
};

export default SideNav;
