// src/components/ContactAttemptModal.js

import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  FormHelperText,
  Slide,
  useTheme,
  IconButton,
  Stack,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const ContactAttemptModal = ({ open, onClose, onSubmit, isAnon }) => {
  const [attemptedContact, setAttemptedContact] = useState('');
  const [contactMethod, setContactMethod] = useState('');
  const [spokeWithGuest, setSpokeWithGuest] = useState('');
  const [wowFactor, setWowFactor] = useState('');
  const [planOfAction, setPlanOfAction] = useState('');
  const [errors, setErrors] = useState({});
  const theme = useTheme();

  const handleAttemptedContactChange = (event) => {
    setAttemptedContact(event.target.value);
    setErrors({});
  };

  const handleContactMethodChange = (event) => {
    setContactMethod(event.target.value);
    setErrors({});
  };

  const handleSpokeWithGuestChange = (event) => {
    setSpokeWithGuest(event.target.value);
    setErrors({});
  };

  const handleWowFactorChange = (event) => {
    setWowFactor(event.target.value);
    setErrors({});
  };

  const handlePlanOfActionChange = (event) => {
    setPlanOfAction(event.target.value);
    setErrors({});
  };

  const handleSubmit = () => {
    let validationErrors = {};

    if (isAnon) {
      // For anon feedback, we only need a plan of action
      if (!planOfAction.trim()) {
        validationErrors.planOfAction = 'Please enter your plan of action.';
      }
    } else {
      // Non-anon logic
      if (attemptedContact !== 'yes') {
        validationErrors.attemptedContact =
          'You cannot dismiss the notification without contacting the guest.';
      } else {
        if (!contactMethod) {
          validationErrors.contactMethod = 'Please select a contact method.';
        }
        if (contactMethod === 'phone' && !spokeWithGuest) {
          validationErrors.spokeWithGuest =
            'Please indicate if you were able to speak with the guest.';
        }
        if (!wowFactor.trim()) {
          validationErrors.wowFactor = 'Please describe how you WOWed the guest.';
        }
        if (!planOfAction.trim()) {
          validationErrors.planOfAction = 'Please enter your plan of action.';
        }
      }
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const contactData = isAnon
        ? {
            planOfAction,
          }
        : {
            attemptedContact,
            contactMethod,
            spokeWithGuest,
            wowFactor,
            planOfAction,
          };

      onSubmit(contactData);
    }
  };

  const handleClose = () => {
    // Reset state when closing the modal
    setAttemptedContact('');
    setContactMethod('');
    setSpokeWithGuest('');
    setWowFactor('');
    setPlanOfAction('');
    setErrors({});
    onClose();
  };

  // Calculate modal width based on contactMethod if not anon
  const modalWidth = isAnon ? '600px' : contactMethod === 'phone' ? '650px' : '600px';

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition>
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Box
          sx={{
            width: '90%',
            maxWidth: modalWidth,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            mx: 'auto',
            mt: '5%',
            borderRadius: 2,
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {/* Decorative Background */}
          <Box
            sx={{
              position: 'absolute',
              top: '-75px',
              right: '-75px',
              width: '150px',
              height: '150px',
              bgcolor: theme.palette.primary.main,
              borderRadius: '50%',
              opacity: 0.1,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: '-50px',
              left: '-50px',
              width: '100px',
              height: '100px',
              bgcolor: theme.palette.secondary.main,
              borderRadius: '50%',
              opacity: 0.1,
            }}
          />

          {/* Close Button */}
          <IconButton
            onClick={handleClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
            Guest Contact Confirmation
          </Typography>

          <Stack spacing={4} sx={{ mt: 3 }}>
            {!isAnon && (
              <FormControl
                component="fieldset"
                error={!!errors.attemptedContact}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                  Was there an attempt to contact the guest?
                </Typography>
                <RadioGroup
                  name="attemptedContact"
                  value={attemptedContact}
                  onChange={handleAttemptedContactChange}
                  sx={{ mt: 1 }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
                {errors.attemptedContact && (
                  <FormHelperText>{errors.attemptedContact}</FormHelperText>
                )}
              </FormControl>
            )}

            {/* For non-anon feedback */}
            {!isAnon && attemptedContact === 'yes' && (
              <>
                <FormControl
                  component="fieldset"
                  error={!!errors.contactMethod}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 'medium' }}
                  >
                    What was the method of contact?
                  </Typography>
                  <RadioGroup
                    name="contactMethod"
                    value={contactMethod}
                    onChange={handleContactMethodChange}
                    sx={{ mt: 1 }}
                  >
                    <FormControlLabel
                      value="phone"
                      control={<Radio color="primary" />}
                      label="Phone Call"
                    />
                    <FormControlLabel
                      value="email"
                      control={<Radio color="primary" />}
                      label="Email"
                    />
                  </RadioGroup>
                  {errors.contactMethod && (
                    <FormHelperText>{errors.contactMethod}</FormHelperText>
                  )}
                </FormControl>

                {contactMethod === 'phone' && (
                  <FormControl
                    component="fieldset"
                    error={!!errors.spokeWithGuest}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 'medium' }}
                    >
                      Were you able to speak with the guest?
                    </Typography>
                    <RadioGroup
                      name="spokeWithGuest"
                      value={spokeWithGuest}
                      onChange={handleSpokeWithGuestChange}
                      sx={{ mt: 1 }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio color="primary" />}
                        label="No - Left a voicemail"
                      />
                    </RadioGroup>
                    {errors.spokeWithGuest && (
                      <FormHelperText>{errors.spokeWithGuest}</FormHelperText>
                    )}
                  </FormControl>
                )}

                <TextField
                  label="How did we WOW! the guest?"
                  placeholder="Describe how you impressed or delighted the guest..."
                  multiline
                  rows={3}
                  value={wowFactor}
                  onChange={handleWowFactorChange}
                  fullWidth
                  sx={{ mt: 1 }}
                  error={!!errors.wowFactor}
                  helperText={errors.wowFactor}
                />
              </>
            )}

            {/* Shared logic for both anon and non-anon when attemptedContact is yes or isAnon */}
            {(isAnon || attemptedContact === 'yes') && (
              <TextField
                label="What is your plan of action?"
                placeholder="Enter your plan of action here..."
                multiline
                rows={4}
                value={planOfAction}
                onChange={handlePlanOfActionChange}
                fullWidth
                sx={{ mt: 1 }}
                error={!!errors.planOfAction}
                helperText={errors.planOfAction}
              />
            )}

            {!isAnon && attemptedContact === 'no' && (
              <Typography color="error">
                You cannot dismiss the notification without contacting the guest.
              </Typography>
            )}
          </Stack>

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ color: '#fff' }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default ContactAttemptModal;
