// src/components/AnalyticsOverviewCard.js

import React, { useEffect, useState } from 'react';
import { Box, Typography, CardActionArea, useTheme, CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FaChartBar } from 'react-icons/fa';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import { collectionGroup, collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import { subDays, startOfDay, addDays, format } from 'date-fns';

const AnalyticsOverviewCard = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [yMax, setYMax] = useState(null);

  useEffect(() => {
    const fetchAndAggregateData = async () => {
      try {
        setLoading(true);
        const now = new Date();

        // Use 29 instead of 30 to include today in the 30-day window
        const cutoff = subDays(startOfDay(now), 29);
        console.log('Cutoff date (30 days including today):', cutoff.toISOString());

        // dayCounts: array of 30 days
        const dayCounts = Array.from({ length: 30 }, () => ({ total: 0, totalNo: 0 }));

        // 1. Query responses from the last 30 days
        const responsesRef = collectionGroup(db, 'responses');
        const responsesQuery = query(responsesRef, where('timestamp', '>', cutoff), orderBy('timestamp', 'asc'));
        console.log('Running Firestore query for responses > cutoff...');
        const responseSnap = await getDocs(responsesQuery);
        console.log(`Responses query returned ${responseSnap.size} documents.`);

        responseSnap.forEach(doc => {
          const d = doc.data();
          const ts = d.timestamp.toDate();
          const dayDiff = Math.floor((ts - cutoff) / (1000 * 60 * 60 * 24));

          if (dayDiff >= 0 && dayDiff < 30) {
            dayCounts[dayDiff].total += 1;
            if (d.responseType === 'no') {
              dayCounts[dayDiff].totalNo += 1;
            }
          }
        });

        // 2. Query OAfeedback from the last 30 days
        // OAfeedback entries are in `feedback` collection at the root.
        const feedbackRef = collection(db, 'feedback');
        const oaFeedbackQuery = query(
          feedbackRef,
          where('timestamp', '>', cutoff),
          where('assignedTo', '==', 'OAfeedback'),
          orderBy('timestamp', 'asc')
        );
        console.log('Running Firestore query for OAfeedback > cutoff...');
        const oaSnap = await getDocs(oaFeedbackQuery);
        console.log(`OAfeedback query returned ${oaSnap.size} documents.`);

        oaSnap.forEach(doc => {
          const d = doc.data();
          const ts = d.timestamp.toDate();
          const dayDiff = Math.floor((ts - cutoff) / (1000 * 60 * 60 * 24));

          if (dayDiff >= 0 && dayDiff < 30) {
            dayCounts[dayDiff].total += 1;

            // Only increment totalNo if l2r < 5
            const l2r = d.l2r;
            if (typeof l2r === 'number' && l2r < 5) {
              dayCounts[dayDiff].totalNo += 1;
            }
          }
        });

        // Convert dayCounts into chartData
        const chartData = dayCounts.map((counts, i) => {
          const currentDate = addDays(cutoff, i);
          const dateLabel = format(currentDate, 'MMM d');
          return {
            date: dateLabel,
            hits: counts.total,
            totalNo: counts.totalNo
          };
        });

        // Determine max for normalization
        let maxVal = 0;
        chartData.forEach(item => {
          if (item.hits > maxVal) maxVal = item.hits;
          if (item.totalNo > maxVal) maxVal = item.totalNo;
        });

        setData(chartData);
        setYMax(maxVal);
      } catch (error) {
        console.error('Error fetching analytics data:', error);
        setData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchAndAggregateData();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
      style={{ flex: '1 1 300px' }}
    >
      <CardActionArea
        onClick={() => navigate('/analytics')}
        sx={{
          backgroundColor: '#ffffff',
          padding: theme.spacing(3),
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <FaChartBar size={48} color={theme.palette.primary.main} />
          <Typography variant="h6" sx={{ fontWeight: 'bold', ml: 2 }}>
            Analytics Overview
          </Typography>
        </Box>

        {loading ? (
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        ) : data && data.length > 0 ? (
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Hits per day in the last 30 days (including today).
            </Typography>

            <Box sx={{ flexGrow: 1, width: '100%', height: 200 }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={data} margin={{ top: 20, right: 20, left: -10, bottom: 0 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" tick={{ fontSize: 12 }} />
                  <YAxis tick={{ fontSize: 12 }} domain={[0, yMax]} />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="hits"
                    stroke={theme.palette.primary.main}
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>

            <Typography variant="body1" sx={{ mt: 4, mb: 2 }}>
              Total 'No' per day in the last 30 days.
            </Typography>

            <Box sx={{ flexGrow: 1, width: '100%', height: 200 }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={data} margin={{ top: 20, right: 20, left: -10, bottom: 0 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" tick={{ fontSize: 12 }} />
                  <YAxis tick={{ fontSize: 12 }} domain={[0, yMax]} />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="totalNo"
                    stroke={theme.palette.error.main}
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>

            <Box sx={{ textAlign: 'right', mt: 2 }}>
              <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
                View Details
              </Typography>
            </Box>
          </>
        ) : (
          <Typography variant="body1">
            No responses or OAfeedback found in the last 30 days.
          </Typography>
        )}
      </CardActionArea>
    </motion.div>
  );
};

export default AnalyticsOverviewCard;
