// src/components/AnalyticsDetailPage.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  createTheme,
  ThemeProvider,
  CircularProgress
} from '@mui/material';
import { motion } from 'framer-motion';
import { db, auth } from '../firebase'; // Adjust import paths as necessary
import {
  collectionGroup,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  doc,
  getDoc
} from 'firebase/firestore';
import { format } from 'date-fns';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';
import { DataGrid } from '@mui/x-data-grid';

const theme = createTheme({
  palette: {
    primary: { main: '#1E88E5' },
    secondary: { main: '#D81B60' },
    error: { main: '#E53935' },
    background: {
      default: '#f4f5f7',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    h4: {
      fontWeight: 700,
    },
  },
});

function computeCutoff(timeframe) {
  if (timeframe === 'all') return null;
  const now = Date.now();
  if (timeframe === '24h') {
    return new Date(now - 24 * 60 * 60 * 1000);
  } else if (timeframe === '7d') {
    return new Date(now - 7 * 24 * 60 * 60 * 1000);
  } else if (timeframe === '30d') {
    return new Date(now - 30 * 24 * 60 * 60 * 1000);
  }
  return null;
}

function getDaypart(date) {
  const hour = date.getHours();
  if (hour >= 5 && hour <= 10) return 'Breakfast';
  if (hour >= 11 && hour <= 13) return 'Lunch';
  if (hour >= 14 && hour <= 16) return 'Afternoon';
  if (hour >= 17 && hour <= 19) return 'Dinner';
  return 'Evening';
}

function getWeekday(date) {
  return format(date, 'EEEE'); // e.g., "Monday"
}

const timeframeOptions = [
  { value: '24h', label: 'Last 24 Hours' },
  { value: '7d', label: 'Last 7 Days' },
  { value: '30d', label: 'Last 30 Days' },
  { value: 'all', label: 'All Time' },
];

const AnalyticsDetailPage = () => {
  // Default filters
  const [sentiment, setSentiment] = useState('both');
  const [rankBy, setRankBy] = useState('employee');
  const [timeframe, setTimeframe] = useState('7d');
  const [selectedClient, setSelectedClient] = useState('all');

  const [loadingData, setLoadingData] = useState(false);
  const [aggregatedData, setAggregatedData] = useState(null);
  const [clientIds, setClientIds] = useState([]);
  const [clientsInfo, setClientsInfo] = useState([]);
  const [loadingClientIds, setLoadingClientIds] = useState(true);
  const [tagMap, setTagMap] = useState({});
  const user = auth.currentUser;

  useEffect(() => {
    // Fetch user clientIds on mount
    const fetchClientIds = async () => {
      if (!user) {
        console.log('User not authenticated');
        setClientIds([]);
        setLoadingClientIds(false);
        return;
      }

      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (!userDocSnap.exists()) {
        console.log('User doc not found');
        setClientIds([]);
        setLoadingClientIds(false);
        return;
      }
      const userData = userDocSnap.data();
      const cIds = userData.clientIds || [];
      console.log('Fetched clientIds:', cIds);
      setClientIds(cIds);
    };
    fetchClientIds();
  }, [user]);

  // Once we have clientIds, fetch their displayNames
  useEffect(() => {
    const fetchClientsDisplayNames = async () => {
      if (clientIds.length === 0) {
        setClientsInfo([]);
        setLoadingClientIds(false);
        return;
      }

      const info = [];
      for (const cid of clientIds) {
        const clientRef = doc(db, 'clients', cid);
        const clientSnap = await getDoc(clientRef);
        if (clientSnap.exists()) {
          const cData = clientSnap.data();
          info.push({ id: cid, displayName: cData.displayName || cid });
        } else {
          // fallback to cid if displayName not found
          info.push({ id: cid, displayName: cid });
        }
      }

      setClientsInfo(info);
      setLoadingClientIds(false);
    };

    if (clientIds.length > 0) {
      fetchClientsDisplayNames();
    } else if (clientIds.length === 0) {
      // No clients
      setClientsInfo([]);
      setLoadingClientIds(false);
    }
  }, [clientIds]);

  // Automatically fetch data when filters or clientIds change
  useEffect(() => {
    if (!loadingClientIds && (clientIds.length > 0 || selectedClient !== 'all')) {
      handleGenerate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentiment, rankBy, timeframe, selectedClient, loadingClientIds, clientIds]);

  const handleSentimentChange = (e) => setSentiment(e.target.value);
  const handleRankByChange = (e) => setRankBy(e.target.value);
  const handleTimeframeChange = (e) => setTimeframe(e.target.value);
  const handleClientChange = (e) => setSelectedClient(e.target.value);

  const chunkArray = (arr, size) => {
    const results = [];
    for (let i = 0; i < arr.length; i += size) {
      results.push(arr.slice(i, i + size));
    }
    return results;
  };

  // Fetch tag data (assignedTo and placement)
  const fetchTagData = async (clientIds) => {
    const tagDataMap = {};
    for (const clientId of clientIds) {
      const tagsRef = collection(db, 'clients', clientId, 'nfcTags');
      const tagSnap = await getDocs(tagsRef);
      tagSnap.forEach(doc => {
        const data = doc.data();
        if (data.id && data.assignedTo) {
          tagDataMap[data.id] = {
            assignedTo: data.assignedTo,
            placement: data.placement || ''
          };
        }
      });
    }
    return tagDataMap;
  };

  const handleGenerate = async () => {
    console.log('Generating data with:', { sentiment, rankBy, timeframe, selectedClient });
    setLoadingData(true);
    setAggregatedData(null);

    try {
      // Determine which clients to query
      let clientsToQuery = [];
      if (selectedClient === 'all') {
        if (clientIds.length === 0) {
          console.log('No clientIds available for user. No data to fetch.');
          setAggregatedData([]);
          setLoadingData(false);
          return;
        }
        clientsToQuery = clientIds;
      } else {
        clientsToQuery = [selectedClient];
      }

      console.log('Fetching tag data...');
      const fetchedTagMap = await fetchTagData(clientsToQuery);
      setTagMap(fetchedTagMap);
      console.log('Fetched tagMap:', fetchedTagMap);

      const cutoff = computeCutoff(timeframe);
      console.log('Computed cutoff:', cutoff);

      const conditions = [];
      if (cutoff) {
        console.log(`Adding timestamp condition: > ${cutoff.toISOString()}`);
        conditions.push(where('timestamp', '>', cutoff));
      }

      let allDocs = [];

      let fetchAllResponses = false;
      let responseTypeCondition = null;

      if (sentiment === 'positive') {
        responseTypeCondition = where('responseType', '==', 'yes');
      } else if (sentiment === 'negative') {
        responseTypeCondition = where('responseType', '==', 'no');
      } else if (sentiment === 'both') {
        fetchAllResponses = true;
      }

      const clientIdChunks = chunkArray(clientsToQuery, 10);
      console.log('ClientId chunks:', clientIdChunks);

      // Fetch responses
      for (const cChunk of clientIdChunks) {
        console.log('Querying responses for client chunk:', cChunk);
        const responsesRef = collectionGroup(db, 'responses');

        let q;
        if (fetchAllResponses) {
          q = query(responsesRef, where('clientId', 'in', cChunk), ...conditions, orderBy('timestamp', 'asc'));
        } else {
          q = query(responsesRef, where('clientId', 'in', cChunk), responseTypeCondition, ...conditions, orderBy('timestamp', 'asc'));
        }

        const responseSnap = await getDocs(q);
        console.log(`Found ${responseSnap.size} documents in responses for chunk.`);
        responseSnap.forEach(docSnap => {
          const data = docSnap.data();
          const responseDocRef = docSnap.ref;
          const nfcTagId = responseDocRef.parent.parent.id;
          allDocs.push({
            ...data,
            derivedTagId: nfcTagId
          });
        });
      }

      // Fetch OAfeedback if needed
      let shouldFetchOAfeedback = false;
      if ((sentiment === 'negative' && rankBy !== 'employee' && rankBy !== 'placement') ||
          (sentiment === 'both' && rankBy !== 'employee' && rankBy !== 'placement')) {
        shouldFetchOAfeedback = true;
      }

      if (shouldFetchOAfeedback) {
        console.log('Fetching OAfeedback since sentiment includes negative and rankBy is not employee or placement.');
        const feedbackConditions = [ where('assignedTo', '==', 'OAfeedback') ];
        if (cutoff) {
          feedbackConditions.push(where('timestamp', '>', cutoff));
          console.log('OAfeedback also filtered by timestamp >', cutoff.toISOString());
        }

        const feedbackRef = collection(db, 'feedback');
        for (const cChunk of clientIdChunks) {
          console.log('Querying OAfeedback for client chunk:', cChunk);
          const fq = query(feedbackRef, where('clientId', 'in', cChunk), ...feedbackConditions, orderBy('timestamp', 'asc'));
          const oaSnap = await getDocs(fq);
          console.log(`Found ${oaSnap.size} OAfeedback documents for chunk.`);
          oaSnap.forEach(docSnap => {
            const data = docSnap.data();
            allDocs.push({
              ...data,
              derivedTagId: data.clientId
            });
          });
        }
      } else {
        console.log('Not fetching OAfeedback due to sentiment/rankBy combination.');
      }

      console.log(`Total documents after merging responses and possibly OAfeedback: ${allDocs.length}`);
      console.log('Aggregating data now...');

      let positiveMap = {};
      let negativeMap = {};

      // Allowed placements when rankBy=placement
      const allowedPlacements = ['Stall/DT', 'Person', 'Counter'];

      for (const d of allDocs) {
        if (!d.timestamp) {
          console.log('Document missing timestamp field:', d);
          continue;
        }

        let key;

        if (rankBy === 'employee') {
          // Only consider tags with placement=='Person'
          const tagInfo = tagMap[d.derivedTagId];
          if (!tagInfo || tagInfo.placement !== 'Person') {
            continue;
          }
          const empName = (tagMap[d.derivedTagId] && tagMap[d.derivedTagId].assignedTo) ? tagMap[d.derivedTagId].assignedTo : 'Unknown Employee';
          key = empName;
        } else if (rankBy === 'day') {
          const ts = d.timestamp.toDate();
          key = getWeekday(ts);
        } else if (rankBy === 'daypart') {
          const ts = d.timestamp.toDate();
          key = getDaypart(ts);
        } else if (rankBy === 'placement') {
          // Group by placement if one of the allowed placements
          const tagInfo = tagMap[d.derivedTagId];
          if (!tagInfo || !allowedPlacements.includes(tagInfo.placement)) {
            continue; // skip if placement is not allowed
          }
          key = tagInfo.placement;
        }

        let isPositive = false;
        let isNegative = false;
        if (d.assignedTo === 'OAfeedback') {
          isNegative = true;
        } else if (d.responseType === 'yes') {
          isPositive = true;
        } else if (d.responseType === 'no') {
          isNegative = true;
        }

        if (sentiment === 'both') {
          if (isPositive) positiveMap[key] = (positiveMap[key] || 0) + 1;
          if (isNegative) negativeMap[key] = (negativeMap[key] || 0) + 1;
        } else if (sentiment === 'positive') {
          if (isPositive) positiveMap[key] = (positiveMap[key] || 0) + 1;
        } else if (sentiment === 'negative') {
          if (isNegative) negativeMap[key] = (negativeMap[key] || 0) + 1;
        }
      }

      let finalData = [];

      if (rankBy === 'day') {
        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        for (const day of weekdays) {
          if (sentiment === 'both') {
            const p = positiveMap[day] || 0;
            const n = negativeMap[day] || 0;
            if (p > 0 || n > 0) {
              finalData.push({ day, positive: p, negative: n, count: p + n });
            }
          } else if (sentiment === 'positive') {
            if (positiveMap[day]) finalData.push({ day, count: positiveMap[day] });
          } else if (sentiment === 'negative') {
            if (negativeMap[day]) finalData.push({ day, count: negativeMap[day] });
          }
        }
      } else if (rankBy === 'daypart') {
        const dayparts = ['Breakfast', 'Lunch', 'Afternoon', 'Dinner', 'Evening'];
        for (const dp of dayparts) {
          if (sentiment === 'both') {
            const p = positiveMap[dp] || 0;
            const n = negativeMap[dp] || 0;
            if (p > 0 || n > 0) {
              finalData.push({ daypart: dp, positive: p, negative: n, count: p + n });
            }
          } else if (sentiment === 'positive') {
            if (positiveMap[dp]) finalData.push({ daypart: dp, count: positiveMap[dp] });
          } else if (sentiment === 'negative') {
            if (negativeMap[dp]) finalData.push({ daypart: dp, count: negativeMap[dp] });
          }
        }
      } else if (rankBy === 'employee') {
        let keys = new Set();
        if (sentiment === 'both') {
          for (let k in positiveMap) keys.add(k);
          for (let k in negativeMap) keys.add(k);
        } else if (sentiment === 'positive') {
          for (let k in positiveMap) keys.add(k);
        } else if (sentiment === 'negative') {
          for (let k in negativeMap) keys.add(k);
        }

        for (const emp of keys) {
          if (sentiment === 'both') {
            const p = positiveMap[emp] || 0;
            const n = negativeMap[emp] || 0;
            if (p > 0 || n > 0) {
              finalData.push({ employee: emp, positive: p, negative: n, count: p + n });
            }
          } else if (sentiment === 'positive') {
            finalData.push({ employee: emp, count: positiveMap[emp] });
          } else if (sentiment === 'negative') {
            finalData.push({ employee: emp, count: negativeMap[emp] });
          }
        }
      } else if (rankBy === 'placement') {
        let keys = new Set();
        if (sentiment === 'both') {
          for (let k in positiveMap) keys.add(k);
          for (let k in negativeMap) keys.add(k);
        } else if (sentiment === 'positive') {
          for (let k in positiveMap) keys.add(k);
        } else if (sentiment === 'negative') {
          for (let k in negativeMap) keys.add(k);
        }

        for (const plc of keys) {
          if (sentiment === 'both') {
            const p = positiveMap[plc] || 0;
            const n = negativeMap[plc] || 0;
            if (p > 0 || n > 0) {
              finalData.push({ placement: plc, positive: p, negative: n, count: p + n });
            }
          } else if (sentiment === 'positive') {
            finalData.push({ placement: plc, count: positiveMap[plc] });
          } else if (sentiment === 'negative') {
            finalData.push({ placement: plc, count: negativeMap[plc] });
          }
        }
      }

      console.log('finalData:', finalData);
      setAggregatedData(finalData);

    } catch (error) {
      console.error('Error generating data:', error);
      setAggregatedData([]);
    } finally {
      setLoadingData(false);
    }
  };

  if (loadingClientIds) {
    return (
      <ThemeProvider theme={theme}>
        <Box 
          sx={{ 
            textAlign: 'center', 
            minHeight: '100vh', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            background: 'linear-gradient(135deg, #f0f2f5 0%, #dce3eb 100%)'
          }}
        >
          <CircularProgress />
        </Box>
      </ThemeProvider>
    );
  }

  let chartData = [];
  if (aggregatedData && aggregatedData.length > 0) {
    if (rankBy === 'day') {
      if (sentiment === 'both') {
        chartData = aggregatedData.map(item => ({
          name: item.day,
          positive: item.positive,
          negative: item.negative
        }));
      } else {
        chartData = aggregatedData.map(item => ({
          name: item.day,
          count: item.count
        }));
      }
    } else if (rankBy === 'daypart') {
      if (sentiment === 'both') {
        chartData = aggregatedData.map(item => ({
          name: item.daypart,
          positive: item.positive,
          negative: item.negative
        }));
      } else {
        chartData = aggregatedData.map(item => ({
          name: item.daypart,
          count: item.count
        }));
      }
    } else if (rankBy === 'employee') {
      if (sentiment === 'both') {
        chartData = aggregatedData.map(item => ({
          name: item.employee,
          positive: item.positive,
          negative: item.negative
        }));
      } else {
        chartData = aggregatedData.map(item => ({
          name: item.employee,
          count: item.count
        }));
      }
    } else if (rankBy === 'placement') {
      if (sentiment === 'both') {
        chartData = aggregatedData.map(item => ({
          name: item.placement,
          positive: item.positive,
          negative: item.negative
        }));
      } else {
        chartData = aggregatedData.map(item => ({
          name: item.placement,
          count: item.count
        }));
      }
    }
  }

  const showBothBars = sentiment === 'both';

  // Determine name field
  let nameField;
  if (rankBy === 'day') {
    nameField = 'day';
  } else if (rankBy === 'daypart') {
    nameField = 'daypart';
  } else if (rankBy === 'employee') {
    nameField = 'employee';
  } else if (rankBy === 'placement') {
    nameField = 'placement';
  }

  let columns = [];
  let rows = [];

  if (aggregatedData && aggregatedData.length > 0) {
    if (sentiment === 'both') {
      columns = [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'positive', headerName: 'Positive', flex: 1, type: 'number' },
        { field: 'negative', headerName: 'Negative', flex: 1, type: 'number' },
        { field: 'count', headerName: 'Total', flex: 1, type: 'number' }
      ];

      rows = aggregatedData.map((item, index) => ({
        id: index,
        name: item[nameField],
        positive: item.positive,
        negative: item.negative,
        count: item.count
      }));
    } else {
      columns = [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'count', headerName: 'Count', flex: 1, type: 'number' }
      ];

      rows = aggregatedData.map((item, index) => ({
        id: index,
        name: item[nameField],
        count: item.count
      }));
    }
  }

return (
  <ThemeProvider theme={theme}>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.3 }}
      style={{
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #f0f2f5 0%, #dce3eb 100%)',
        padding: '40px 0'
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            fontFamily: 'Montserrat, sans-serif',
            textAlign: 'center',
            color: '#333',
            mb: 2
          }}
        >
          Analytics Detail
        </Typography>

        {/* Removed the descriptive line here */}

        {/* Make the filter card full width so all filters are on one line */}
        <Box sx={{ mb: 4 }}>
          <Card
            sx={{
              width: '100%',
              borderRadius: '12px',
              boxShadow: '0 6px 20px rgba(0,0,0,0.1)',
              p: 1,
              background: '#fff'
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  mb: 1,
                  fontSize: '1.25rem',
                  color: '#333'
                }}
              >
                Filters
              </Typography>

              {/* Display all filters in one row, centered */}
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 1,
                  mb: 2,
                  justifyContent: 'center' // Centering the filters
                }}
              >
                {/* Sentiment Selector */}
                <FormControl sx={{ minWidth: 150 }} size="small">
                  <InputLabel id="sentiment-select-label">Sentiment Type</InputLabel>
                  <Select
                    labelId="sentiment-select-label"
                    value={sentiment}
                    label="Sentiment Type"
                    onChange={handleSentimentChange}
                  >
                    <MenuItem value="positive">Positive</MenuItem>
                    <MenuItem value="negative">Negative</MenuItem>
                    <MenuItem value="both">Both</MenuItem>
                  </Select>
                </FormControl>

                {/* Timeframe Selector */}
                <FormControl sx={{ minWidth: 150 }} size="small">
                  <InputLabel id="timeframe-select-label">Timeframe</InputLabel>
                  <Select
                    labelId="timeframe-select-label"
                    value={timeframe}
                    label="Timeframe"
                    onChange={handleTimeframeChange}
                  >
                    {timeframeOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Client Selector */}
                <FormControl sx={{ minWidth: 150 }} size="small">
                  <InputLabel id="client-select-label">Client</InputLabel>
                  <Select
                    labelId="client-select-label"
                    value={selectedClient}
                    label="Client"
                    onChange={handleClientChange}
                  >
                    <MenuItem value="all">All</MenuItem>
                    {clientsInfo.map((c) => (
                      <MenuItem key={c.id} value={c.id}>{c.displayName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Rank By Selector */}
                <FormControl sx={{ minWidth: 150 }} size="small">
                  <InputLabel id="rankby-select-label">Rank By</InputLabel>
                  <Select
                    labelId="rankby-select-label"
                    value={rankBy}
                    label="Rank By"
                    onChange={handleRankByChange}
                  >
                    <MenuItem value="day">Day (of week)</MenuItem>
                    <MenuItem value="daypart">Daypart</MenuItem>
                    <MenuItem value="employee">Employee</MenuItem>
                    <MenuItem value="placement">Placement</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </CardContent>
          </Card>
        </Box>

        <Box sx={{ mt: 4, minHeight: '400px', position: 'relative' }}>
          {loadingData ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 4,
                height: '300px'
              }}
            >
              <CircularProgress />
            </Box>
          ) : aggregatedData && aggregatedData.length > 0 ? (
            <Box
              sx={{
                mt: 4,
                background: '#fff',
                borderRadius: '12px',
                boxShadow: '0 6px 20px rgba(0,0,0,0.1)',
                p: 4
              }}
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, color: '#333' }}>
                Results
              </Typography>

              {/* Chart Section with Legend on top */}
              <Box sx={{ width: '100%', height: 300, mb: 1 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={chartData}
                    margin={{ top: 20, right: 20, left: 0, bottom: 40 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      tick={{ fontSize: 12 }}
                      angle={-45}
                      textAnchor="end"
                      interval={0}
                      height={60}
                    />
                    <YAxis tick={{ fontSize: 12 }} />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36} />
                    {showBothBars ? (
                      <>
                        <Bar
                          dataKey="positive"
                          fill={theme.palette.primary.main}
                          stackId="a"
                          name="Positive"
                        />
                        <Bar
                          dataKey="negative"
                          fill={theme.palette.error.main}
                          stackId="a"
                          name="Negative"
                        />
                      </>
                    ) : (
                      <Bar
                        dataKey="count"
                        fill={sentiment === 'positive' ? theme.palette.primary.main : theme.palette.error.main}
                      />
                    )}
                  </BarChart>
                </ResponsiveContainer>
              </Box>

              {/* Modernized Table */}
				<Box sx={{ height: '100%', maxHeight: '500px', width: '100%', overflow: 'auto' }}>
				  <DataGrid
					rows={rows}
					columns={columns}
					pageSize={5}
					rowsPerPageOptions={[5, 10, 20]}
					disableSelectionOnClick
					sx={{
					  borderRadius: '8px',
					  boxShadow: '0 2px 10px rgba(0,0,0,0.05)',
					  border: 'none',
					  fontFamily: 'Poppins, sans-serif'
					}}
				  />
				</Box>
            </Box>
          ) : (
            <Box
              sx={{
                mt: 2,
                background: '#fff',
                borderRadius: '12px',
                boxShadow: '0 6px 20px rgba(0,0,0,0.1)',
                p: 4,
                textAlign: 'center'
              }}
            >
              <Typography variant="body1" sx={{ mt: 2, color: '#777' }}>
                No data available for the selected criteria.
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
    </motion.div>
  </ThemeProvider>
);

};

export default AnalyticsDetailPage;
