// src/components/Dashboard.js

import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import RecentActivityCard from './RecentActivityCard';
import NotificationsCard from './NotificationsCard';
import AnalyticsOverviewCard from './AnalyticsOverviewCard';

const Dashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5 }}
    >
      <Typography
        variant={{ xs: 'h5', md: 'h4' }}
        gutterBottom
        sx={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}
      >
        Welcome to your Dashboard
      </Typography>

      <Box
        sx={{
          mt: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(4),
        }}
      >
        {/* Top row with RecentActivityCard and NotificationsCard side by side on md+ screens */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: theme.spacing(4),
          }}
        >
          <RecentActivityCard />
          <NotificationsCard />
        </Box>

        {/* AnalyticsOverviewCard below the two cards */}
        <AnalyticsOverviewCard />
      </Box>
    </motion.div>
  );
};

export default Dashboard;
