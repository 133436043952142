// src/components/Leaderboard.js

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Tooltip,
  IconButton
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { db } from '../firebase';
import { doc, collection, getDocs, query, where, collectionGroup, orderBy, onSnapshot } from 'firebase/firestore';

const Leaderboard = () => {
  const [searchParams] = useSearchParams();
  const contestId = searchParams.get('contestId');
  const providedPassword = searchParams.get('pw'); // hashed password from the URL

  const [loading, setLoading] = useState(true);
  const [accessDenied, setAccessDenied] = useState(false);
  const [contest, setContest] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [tagMap, setTagMap] = useState({});
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  useEffect(() => {
    // No direct action until we have contest and password
  }, [contestId, providedPassword]);

  // Listen to contest doc in real-time
  useEffect(() => {
    if (!contestId || !providedPassword) {
      if (!contestId || !providedPassword) {
        setErrorMsg('Missing contestId or password in the URL.');
        setLoading(false);
      }
      return;
    }

    const contestRef = doc(db, 'contests', contestId);
    const unsubContest = onSnapshot(contestRef, (snapshot) => {
      if (!snapshot.exists()) {
        setErrorMsg('Contest not found.');
        setLoading(false);
        return;
      }

      const contestData = snapshot.data();
      // Check hashed password
      if (contestData.hashedPassword !== providedPassword) {
        setAccessDenied(true);
        setLoading(false);
        return;
      }

      // If access is granted
      setContest(contestData);
      setAccessDenied(false);
      setLoading(false);
    }, (error) => {
      console.error('Error fetching contest:', error);
      setErrorMsg('Failed to load contest. Please try again.');
      setLoading(false);
    });

    return () => {
      unsubContest();
    };
  }, [contestId, providedPassword]);

  // Once we have a contest (and not denied), we can fetch employees and responses
  useEffect(() => {
    let unsubResponses;
    if (contest && !accessDenied) {
      setLoading(true);

      const fetchData = async () => {
        try {
          // Determine client IDs
          let clientIds = [];
          if (Array.isArray(contest.clientIds)) {
            clientIds = contest.clientIds;
          } else if (contest.clientId) {
            clientIds = [contest.clientId];
          }

          if (clientIds.length === 0) {
            setErrorMsg('No clients associated with this contest.');
            setLoading(false);
            return;
          }

          // Build a combined tagMap from all clients
          const tmpTagMap = {};
          for (const cid of clientIds) {
            const tagsRef = collection(db, 'clients', cid, 'nfcTags');
            const tagsQ = query(tagsRef, where('placement', '==', 'Person'));
            const tagsSnap = await getDocs(tagsQ);

            tagsSnap.forEach(docSnap => {
              const data = docSnap.data();
              if (data.assignedTo && data.id) {
                tmpTagMap[data.id] = data.assignedTo;
              }
            });
          }

          setTagMap(tmpTagMap);

          const employees = Array.from(new Set(Object.values(tmpTagMap)));
          const ignoreList = contest.ignoreList || [];
          const filtered = employees.filter(emp => !ignoreList.includes(emp));
          setFilteredEmployees(filtered);

          // Listen to responses in real-time
          const start = contest.startDate.toDate();
          const end = contest.endDate.toDate();

          const responsesRef = collectionGroup(db, 'responses');

          // We have multiple clients. We need to fetch responses from all of them.
          // We'll listen to each client's responses and combine them.
          // However, onSnapshot can only listen to one query at a time.
          // A workaround: we can combine queries if we rely on a single query that matches all clientIds,
          // but Firestore doesn't support array-contains-any for different fields easily.
          // Instead, we'll run multiple onSnapshot listeners, one for each client, and combine data.

          // To keep it simple: We'll fetch responses once. If real-time updates are not strictly required for multiple clients,
          // we can remove the real-time aspect and just do getDocs. If you want real-time for multiple clients,
          // you'd need multiple unsub references and combine results each time.

          // For simplicity here, let's just do a one-time fetch (no real-time for multiple clients):
          // If you must keep real-time, you'd replicate this logic with multiple onSnapshots and combine them.

          const combinedResponses = [];
          for (const cid of clientIds) {
            const responsesQ = query(
              responsesRef,
              where('clientId', '==', cid),
              where('timestamp', '>=', start),
              where('timestamp', '<=', end),
              orderBy('timestamp', 'asc')
            );
            const respSnap = await getDocs(responsesQ);
            respSnap.forEach(respDoc => combinedResponses.push(respDoc));
          }

          // Now process combinedResponses
          const counts = {};
          filtered.forEach(emp => (counts[emp] = 0));

          combinedResponses.forEach(respDoc => {
            const data = respDoc.data();
            const parentRef = respDoc.ref.parent.parent;
            const tagId = parentRef ? parentRef.id : null;

            if (tagId && tmpTagMap[tagId]) {
              const empName = tmpTagMap[tagId];
              if (counts.hasOwnProperty(empName)) {
                counts[empName] += 1;
              }
            }
          });

          // Sort employees by count descending
          const sortedEmployees = Object.entries(counts)
            .sort((a, b) => b[1] - a[1])
            .map(([name, count]) => ({ name, count }));

          setEmployeeData(sortedEmployees);
          setLoading(false);

        } catch (error) {
          console.error('Error fetching employees or responses:', error);
          setErrorMsg('Failed to load employees. Please try again.');
          setLoading(false);
        }
      };

      fetchData();
    }

    return () => {
      if (unsubResponses) unsubResponses();
    };
  }, [contest, accessDenied]);

  if (loading) {
    return (
      <Box
        sx={{
          textAlign: 'center',
          marginTop: '50px',
          display: 'flex',
          justifyContent: 'center',
          minHeight: '100vh',
          alignItems: 'center',
          backgroundColor: '#f7f7f7'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (accessDenied) {
    return (
      <Box sx={{ p: 4, textAlign: 'center', backgroundColor: '#f7f7f7', minHeight: '100vh' }}>
        <Typography variant="h4" sx={{ mb: 2 }}>Access Denied</Typography>
        <Typography variant="body1">
          The provided password does not match the contest's leaderboard.
        </Typography>
      </Box>
    );
  }

  if (errorMsg) {
    return (
      <Box sx={{ p: 4, backgroundColor: '#f7f7f7', minHeight: '100vh' }}>
        <Alert severity="error">{errorMsg}</Alert>
      </Box>
    );
  }

  if (!contest) {
    return (
      <Box sx={{ p: 4, textAlign: 'center', backgroundColor: '#f7f7f7', minHeight: '100vh' }}>
        <Typography variant="body1">No contest found.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, backgroundColor: '#f7f7f7', minHeight: '100vh' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2, gap: 1 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>
          {contest.title} Leaderboard
        </Typography>
        {contest.description && (
          <Tooltip title={contest.description} arrow enterTouchDelay={0}>
            <IconButton size="small" sx={{ color: 'text.secondary' }}>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Typography variant="body1" sx={{ mb: 4, textAlign: 'center', color: 'text.secondary' }}>
        Contest Period: {contest.startDate.toDate().toLocaleDateString()} - {contest.endDate.toDate().toLocaleDateString()}
      </Typography>

      {employeeData.length === 0 ? (
        <Typography variant="body1" sx={{ textAlign: 'center', color: 'text.secondary' }}>
          No data available.
        </Typography>
      ) : (
        <Paper sx={{ borderRadius: '12px', overflow: 'hidden', boxShadow: '0 4px 10px rgba(0,0,0,0.1)' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#eaeaea' }}>
                <TableCell sx={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }}>Employee</TableCell>
                <TableCell sx={{ fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif' }} align="right">Responses</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeData.map((emp, index) => (
                <TableRow key={emp.name} sx={{ '&:nth-of-type(even)': { backgroundColor: '#fafafa' } }}>
                  <TableCell sx={{ fontFamily: 'Montserrat, sans-serif' }}>{index + 1}. {emp.name}</TableCell>
                  <TableCell sx={{ fontFamily: 'Montserrat, sans-serif' }} align="right">{emp.count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </Box>
  );
};

export default Leaderboard;
