// src/components/ForgotPassword.js

import React, { useState } from 'react';
import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  FormLabel,
  Text,
  useToast,
  VStack,
  HStack,
  Heading,
  Link,
} from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { motion, isValidMotionProp } from 'framer-motion';
import { chakra } from '@chakra-ui/react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase'; // Import the auth object

const MotionBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) ||
    [
      'children',
      'width',
      'height',
      'bg',
      'bgGradient',
      'position',
      'zIndex',
      'top',
      'left',
      'right',
      'bottom',
      'display',
      'alignItems',
      'justifyContent',
      'p',
      'overflow',
      'rounded',
      'boxShadow',
      'maxW',
      'initial',
      'animate',
      'transition',
      'mb',
      'textAlign',
      'fontSize',
      'color',
      'textShadow',
      'onSubmit',
      'id',
      'isRequired',
      'type',
      'placeholder',
      'variant',
      'value',
      'onChange',
      'focusBorderColor',
      'aria-label',
      'icon',
      'onClick',
      'size',
      'colorScheme',
      '_hover',
    ].includes(prop),
});

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    // Reset previous errors
    setEmailError(false);

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.trim())) {
      setEmailError(true);
      toast({
        title: 'Invalid Email',
        description: 'Please enter a valid email address.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);

    try {
      await sendPasswordResetEmail(auth, email.trim());
      toast({
        title: 'Password Reset Email Sent',
        description:
          'Please check your email for instructions to reset your password.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate('/login');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      toast({
        title: 'Error',
        description:
          error.code === 'auth/user-not-found'
            ? 'No user found with this email.'
            : 'Failed to send password reset email. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      minH="100vh"
      bgGradient="linear(to-r, gray.700, gray.900)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      p="4"
      position="relative"
      overflow="hidden"
    >
      {/* Overlay for better readability */}
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg="blackAlpha.600"
        zIndex="0"
      />

      {/* Forgot Password Form */}
      <MotionBox
        bg="rgba(255, 255, 255, 0.1)"
        backdropFilter="blur(10px)"
        borderRadius="lg"
        boxShadow="2xl"
        zIndex="1"
        maxW="md"
        width="100%"
        p={['6', '8']}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
      >
        <VStack spacing="6" align="stretch">
          {/* Logo and Title */}
          <Box textAlign="center">
            <Image
              src="/images/icononly.png" // Adjust the path as necessary
              alt="TrueMetric.io Logo"
              boxSize={['80px', '120px']}
              objectFit="contain"
              mx="auto"
            />
            <Text
              bgGradient="linear(to-r, white, blue.500, purple.400)"
              bgClip="text"
              fontSize={['xl', '2xl']}
              fontWeight="semibold"
              mt="2"
              textAlign="center"
            >
              TrueMetric.io
            </Text>
          </Box>

          <Heading
            as="h2"
            size="lg"
            textAlign="center"
            color="whiteAlpha.800"
          >
            Forgot Your Password?
          </Heading>
          <Text textAlign="center" color="gray.300" mb="6">
            Enter your email address below and we'll send you a link to reset your password.
          </Text>

          <form onSubmit={handlePasswordReset}>
            <VStack spacing="4">
              <FormControl id="email" isRequired isInvalid={emailError}>
                <FormLabel color="whiteAlpha.800">Email Address</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <EmailIcon color="orange.400" />
                  </InputLeftElement>
                  <Input
                    type="email"
                    placeholder="Enter your email"
                    variant="filled"
                    bg="whiteAlpha.300"
                    _hover={{ bg: 'whiteAlpha.400' }}
                    _focus={{ bg: 'whiteAlpha.400' }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    focusBorderColor="orange.400"
                    borderRadius="md"
                  />
                </InputGroup>
                {emailError && (
                  <Text color="red.500" fontSize="sm" mt="1">
                    Please enter a valid email address.
                  </Text>
                )}
              </FormControl>

              <Button
                type="submit"
                colorScheme="orange"
                size="lg"
                width="full"
                isLoading={isSubmitting}
                _hover={{
                  bg: 'orange.500',
                  transform: 'scale(1.05)',
                }}
                leftIcon={<EmailIcon />}
                transition="all 0.3s ease"
              >
                Send Reset Link
              </Button>
            </VStack>
          </form>

          <HStack spacing="2" justify="center" mt="4">
            <Text color="gray.300">Remember your password?</Text>
            <Link as={RouterLink} to="/login" color="orange.300" fontWeight="semibold">
              Log In
            </Link>
          </HStack>
        </VStack>
      </MotionBox>
    </Box>
  );
};

export default ForgotPassword;
