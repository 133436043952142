// src/components/NotificationsPage.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Divider,
  Grid,
} from '@mui/material';
import { auth, db } from '../firebase';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
} from 'firebase/firestore';
import { format } from 'date-fns';
import ContactAttemptModal from './ContactAttemptModal';

const NotificationsPage = () => {
  const [loading, setLoading] = useState(true);
  const [feedbackItems, setFeedbackItems] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isAnon, setIsAnon] = useState(false);

  useEffect(() => {
    const fetchFeedbackItems = async () => {
      try {
        const currentUser = auth.currentUser;

        if (!currentUser) {
          throw new Error('User not authenticated');
        }

        const uid = currentUser.uid;
        const userDocRef = doc(db, 'users', uid);
        const userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) {
          throw new Error('User data not found');
        }

        const userData = userDocSnap.data();
        const clientIds = userData.clientIds || [];
        console.log("CLIENTS: ", clientIds);

        if (clientIds.length === 0) {
          setFeedbackItems([]);
          setLoading(false);
          return;
        }

        let allTagIds = [];
        for (const clientId of clientIds) {
          const nfcTagsCollectionRef = collection(db, 'clients', clientId, 'nfcTags');
          const nfcTagsSnapshot = await getDocs(nfcTagsCollectionRef);
          nfcTagsSnapshot.forEach((tagDoc) => {
            const nfcTagId = tagDoc.id;
            allTagIds.push(nfcTagId);
          });
        }

        allTagIds = allTagIds.filter((id) => id != null);

        const feedbackCollectionRef = collection(db, 'feedback');
        let allFeedbackItems = [];

        // Tag-based feedback (non-OA)
        if (allTagIds.length > 0) {
          const tagChunks = [];
          for (let i = 0; i < allTagIds.length; i += 10) {
            tagChunks.push(allTagIds.slice(i, i + 10));
          }

          for (const chunk of tagChunks) {
            const feedbackQuery = query(
              feedbackCollectionRef,
              where('tagId', 'in', chunk),
              where('isRead', '==', false)
            );

            const feedbackSnapshot = await getDocs(feedbackQuery);
            feedbackSnapshot.forEach((doc) => {
              const data = doc.data();
              allFeedbackItems.push({ id: doc.id, ...data });
            });
          }
        }

        // OAfeedback (unread)
        const clientChunks = [];
        for (let i = 0; i < clientIds.length; i += 10) {
          clientChunks.push(clientIds.slice(i, i + 10));
        }

        for (const chunk of clientChunks) {
          const oaFeedbackQuery = query(
            feedbackCollectionRef,
            where('clientId', 'in', chunk),
            where('assignedTo', '==', 'OAfeedback'),
            where('isRead', '==', false)
          );

          const oaFeedbackSnapshot = await getDocs(oaFeedbackQuery);
          oaFeedbackSnapshot.forEach((doc) => {
            const data = doc.data();
            allFeedbackItems.push({ id: doc.id, ...data });
          });
        }

        // Remove duplicates
        const uniqueFeedback = [];
        const seenIds = new Set();

        for (const item of allFeedbackItems) {
          if (!seenIds.has(item.id)) {
            seenIds.add(item.id);
            uniqueFeedback.push(item);
          }
        }

        // Sort feedback items by timestamp desc
        uniqueFeedback.sort((a, b) => b.timestamp - a.timestamp);

        setFeedbackItems(uniqueFeedback);
      } catch (error) {
        console.error('Error fetching feedback items:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFeedbackItems();
  }, []);

  const handleFeedbackClick = async (feedback) => {
    // Determine if anon
    const isAnonFeedback = !(feedback.name || feedback.email || feedback.phone);

    // If OAfeedback and l2r is 4 or 5, mark as read and remove immediately
    if (feedback.assignedTo === 'OAfeedback' && typeof feedback.l2r === 'number' && feedback.l2r >= 4) {
      try {
        const feedbackDocRef = doc(db, 'feedback', feedback.id);
        await updateDoc(feedbackDocRef, {
          isRead: true,
        });

        setFeedbackItems((prevItems) =>
          prevItems.filter((item) => item.id !== feedback.id)
        );
      } catch (error) {
        console.error('Error updating feedback (OA l2r >= 4):', error);
      }
      return;
    }

    // Otherwise, open the modal
    setIsAnon(isAnonFeedback);
    setSelectedFeedback(feedback);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedFeedback(null);
    setIsAnon(false);
  };

  const handleModalSubmit = async (contactData) => {
    try {
      const feedbackDocRef = doc(db, 'feedback', selectedFeedback.id);
      await updateDoc(feedbackDocRef, {
        isRead: true,
        contactAttempt: contactData,
      });

      setFeedbackItems((prevItems) =>
        prevItems.filter((item) => item.id !== selectedFeedback.id)
      );

      handleModalClose();
    } catch (error) {
      console.error('Error updating feedback:', error);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          textAlign: 'center',
          marginTop: '50px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Notifications
      </Typography>
      {feedbackItems.length === 0 ? (
        <Typography variant="body1">No new notifications.</Typography>
      ) : (
        <Grid container spacing={2}>
          {feedbackItems.map((feedback) => (
            <Grid item xs={12} key={feedback.id}>
              <Card
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                  },
                }}
                onClick={() => handleFeedbackClick(feedback)}
              >
                <CardContent>
                  <Typography variant="subtitle2" color="textSecondary">
                    {feedback.timestamp
                      ? `${format(feedback.timestamp.toDate(), 'MMMM d, yyyy h:mm a')} | ${feedback.displayName || 'Unknown Client'}`
                      : feedback.displayName || 'Unknown Client'}
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  {feedback.name || feedback.email || feedback.phone ? (
                    <>
                      {feedback.name && (
                        <Typography variant="h6">
                          {feedback.name}
                        </Typography>
                      )}
                      {feedback.email && (
                        <Typography variant="body2">
                          Email: {feedback.email}
                        </Typography>
                      )}
                      {feedback.phone && (
                        <Typography variant="body2">
                          Phone: {feedback.phone}
                        </Typography>
                      )}
                      {feedback.feedback && (
                        <Typography variant="body1" sx={{ mt: 1 }}>
                          {feedback.feedback}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <>
                      {feedback.feedback && (
                        <Typography variant="body1">
                          {feedback.feedback}
                        </Typography>
                      )}
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {selectedFeedback && (
        <ContactAttemptModal
          open={modalOpen}
          onClose={handleModalClose}
          onSubmit={handleModalSubmit}
          isAnon={isAnon}
        />
      )}
    </Box>
  );
};

export default NotificationsPage;
