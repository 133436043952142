// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import ThankYouPage from './components/ThankYouPage';
import ApologyPage from './components/ApologyPage';
import NotFoundPage from './components/NotFoundPage';
import MagStripeWriter from './components/MagStripeWriter';
import ClientDataProcessor from './components/ClientDataProcessor';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings'; // Import Settings component
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './components/MainLayout';
import NotificationsPage from './components/NotificationsPage';
import ActivityPage from './components/ActivityPage';
import AnalyticsDetailPage from './components/AnalyticsDetailPage';
import ContestsPage from './components/ContestsPage'; // Import the new ContestsPage component
import Leaderboard from './components/Leaderboard'; 
import { AuthProvider } from './contexts/AuthContext';
import ChakraWrapper from './components/ChakraWrapper';
import ManageTags from './components/ManageTags';


function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<HomePage />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/apology" element={<ApologyPage />} />
		  <Route path="/leaderboard" element={<Leaderboard />} /> 

          {/* Authentication Routes */}
          <Route
            path="/login"
            element={
              <ChakraWrapper>
                <Login />
              </ChakraWrapper>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <ChakraWrapper>
                <ForgotPassword />
              </ChakraWrapper>
            }
          />
          <Route
            path="/reset-password"
            element={
              <ChakraWrapper>
                <ResetPassword />
              </ChakraWrapper>
            }
          />

          {/* General Protected Routes */}
          <Route element={<ProtectedRoute />}>
            <Route element={<MainLayout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              {/* Update Settings Route to remove ChakraWrapper */}
              <Route path="/settings" element={<Settings />} />
              <Route path="/notifications" element={<NotificationsPage />} />
              <Route path="/activity" element={<ActivityPage />} />
              <Route path="/analytics" element={<AnalyticsDetailPage />} />
			  <Route path="/manage-tags" element={<ManageTags />} />
              <Route path="/contests" element={<ContestsPage />} /> {/* New Contests Page route */}
			            {/* Specifically Protected MagStripe Writer Route */}
          <Route
            element={<ProtectedRoute allowedUids={['YHHq95EEzIgXUcnFzehvM4GCAMH3']} />}
          >
            <Route path="/magstripe-writer" element={<MagStripeWriter />} />
			</Route>
			
		 <Route
            element={<ProtectedRoute allowedUids={['YHHq95EEzIgXUcnFzehvM4GCAMH3']} />}
          >
            <Route path="/client-data" element={<ClientDataProcessor />} />
          </Route>

              {/* Add more general protected routes here */}
            </Route>
          </Route>


          {/* Catch-all Route */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
